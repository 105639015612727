import React, {useEffect, useRef, useState} from 'react'
import Headroom from "react-headroom";
import {NavLink} from "react-router-dom";
import Carousel from 'react-elastic-carousel'
import {gsap} from "gsap";
import LpssAbout from "../Component/LpssAbout";
import LpssStrategy from "../Component/LpssStrategy";
import LpssFacilities from "../Component/LpssFacilities";
import LpssTeam from "../Component/LpssTeam";
import LpssJourney from "../Component/LpssJourney";
// import LpssSustainablity from "../Component/LpssSustainablity";
import LpssContact from "../Component/LpssContact";
import LpssFooter from '../Component/LpssFooter';
import {HashLink} from 'react-router-hash-link';
import LpssSustainablity from "../Component/LpssSustainablity";
import LpssSocial from "../Component/LpssSocial";
import LpssPartners from "../Component/LpssPartners";

const lpss_logo = require("../../assets/lpss.png")
const slider1 = require("../../assets/Banner6.jpg")
const slider2 = require("../../assets/banner_bg.jpg")
const breakPoints = [
    {width: 1, itemsToShow: 1},
]


const sliders = [
    {
        title: "Building Healthy Communities, Together",
        caption: "At Lal Pari Swasthya Seva Sadan (LPS Sadan), we are dedicated to bridging the healthcare divide in rural communities. We believe that quality healthcare is a fundamental right, and we strive to make it accessible to every resident in Devhitol village, Madhubani district, Bihar.",
        image: slider1,
        cta: {
            text: "Donate",
            link: "/donate"
        }
    },
    {
        title: "State-of-the-Art Facilities for Comprehensive Care",
        caption: "Our health center is equipped with modern amenities, offering general consultations, emergency care, diagnostic tests, and much more, all under one roof.",
        image: slider2,
        cta: null
    },
    // {
    //     title: "Harnessing Solar Energy for a Sustainable Future",
    //     caption: "Join us on a journey towards a greener future. Our solar panel equipped campus ensures clean and renewable energy, contributing to a sustainable environment.",
    //     image: slider1,
    //     cta: null
    // },
    {
        title: "Our Journey: A Beacon of Hope and Healing",
        caption: "From barren land to a trusted health center, LPS Sadan embodies hope and dedication. We are a testament to the transformative power of compassion and commitment.",
        image: slider1,
        cta: null
    }
]


function LPSSS() {
    const sliderRef = useRef(null)

    useEffect(() => {
        const body = document.querySelector('#root');
        body.scrollIntoView({
            behavior: 'smooth'
        }, 500)
    }, []);
    useEffect(() => {
        gsap.fromTo(sliderRef.current.slider.children[0].childNodes[0].childNodes[0].children[0].children[1].childNodes[0].childNodes, {
            autoAlpha: 0,
            y: 100
        }, {autoAlpha: 1, y: 0, duration: .25, stagger: 0.1})
    }, []);
    return (
        <div className="tw-font-primary tw-text-complement">
            <Header/>
            <div className="relative w-full tw-w-screen tw-overflow-hidden">
                <Carousel breakPoints={breakPoints}
                          enableAutoPlay
                          ref={sliderRef}
                          autoPlaySpeed={4000}
                          removeArrowOnDeviceType={["tablet", "mobile"]}
                          infinite={true}
                          showArrows={false}
                          pagination={false}
                          onChange={(currentItem, pageIndex) => {
                              const sliderElements = sliderRef.current.slider.children[0].childNodes[pageIndex].childNodes[0].children[0].children[1].childNodes[0].childNodes
                              gsap.fromTo(sliderElements, {autoAlpha: 0, y: 100}, {
                                  autoAlpha: 1,
                                  y: 0,
                                  duration: .25,
                                  stagger: 0.1
                              })
                              const prevSliderElements = sliderRef.current.slider.children[0].childNodes[pageIndex === 0 ? (sliders.length - 1) : pageIndex - 1].childNodes[0].children[0].children[1].childNodes[0].childNodes
                              gsap.set(prevSliderElements, {autoAlpha: 0, y: 100})
                              if (currentItem.index === sliders.length - 1) {
                                  setTimeout(() => {
                                      sliderRef.current.goTo(0);
                                  }, 4000);
                              }
                          }}>
                    {
                        sliders.map((slide, i) => <div className="tw-w-full tw-h-screen tw-relative" key={i}>
                            <img className="tw-w-full tw-h-full tw-object-cover" src={slide.image} alt=""/>
                            <div
                                className="tw-absolute tw-inset-0 tw-bg-black/70 tw-flex tw-flex-col tw-items-center tw-justify-center">
                                <div className="tw-w-full md:tw-w-5/6 tw-mx-auto">
                                    <h2 className="tw-text-4xl md:tw-text-5xl tw-text-white tw-font-bold tw-text-center tw-opacity-0">"{slide.title}"</h2>
                                    <p className="tw-px-4 md:tw-px-10 tw-text-center tw-text-white tw-text-xl md:tw-text-2xl tw-mt-6 tw-opacity-0">{slide.caption}</p>
                                    {
                                        slide.cta !== null &&
                                        <div
                                            className="tw-w-full tw-flex tw-items-center tw-justify-center tw-mt-6 tw-opacity-0">
                                            <a className="tw-bg-accent/80 tw-px-5 tw-py-3.5 tw-text-white tw-text-xl tw-rounded-2xl"
                                               href={slide.cta.link}>
                                                {slide.cta.text}
                                            </a>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>)
                    }
                </Carousel>
            </div>
            <LpssAbout/>
            <LpssStrategy/>
            <LpssFacilities/>
            <LpssTeam/>
            <LpssSocial/>
            <LpssPartners/>
            {/*<LpssJourney/>*/}
            {/* <LpssSustainablity/>*/}
            <LpssContact/>
            <LpssFooter/>
        </div>
    )
}

function Header() {
    const [toggle, setToggle] = useState(false);
    return (
        <Headroom>
            <div className="tw-font-primary">
                <div
                    className="tw-w-full tw-hidden lg:tw-flex tw-justify-between tw-py-4 tw-px-20 tw-bg-accent tw-text-white ">
                    <div className="tw-flex tw-divide-x tw-divide-white tw-items-center">
                        <div className="tw-px-2.5 tw-text-base">Phone: <a
                            className="tw-text-white hover:tw-text-primary tw-transition-colors"
                            href="tel:917091804541">+91-7091804541</a></div>
                        <div className="tw-px-2.5 tw-text-base">Email: <a
                            className="tw-text-white hover:tw-text-primary tw-transition-colors"
                            href="mailto:jhatara123@gmail.com">jhatara123@gmail.com</a></div>
                        <div className="tw-px-2.5 tw-text-base">
                            <a className="tw-text-white hover:tw-text-primary tw-transition-colors px-2"
                               href="https://www.facebook.com/profile.php?id=100086580764534"
                               target="_blank"><i className="fab fa-facebook-f"></i></a>
                            <a className="tw-text-white hover:tw-text-primary tw-transition-colors px-2"
                               href="https://www.linkedin.com/company/yadunandan-shiksha-seva-sansthan"
                               target="_blank"><i className="fab fa-linkedin-in"></i></a>
                            <a className="tw-text-white hover:tw-text-primary tw-transition-colors px-2"
                               href="https://wa.me/919470883299"
                               target="_blank"><i className="fab fa-whatsapp"></i></a>
                        </div>
                    </div>
                    <div className="tw-flex tw-divide-x tw-divide-white tw-justify-end">
                        <div className="tw-px-2.5 tw-text-base">Reg.No. - R-7429</div>
                        <div className="tw-px-2.5 tw-text-base">PAN No. - AAATY6820A</div>
                    </div>
                </div>

                <div className=" tw-w-full tw-py-2 tw-px-4 md:tw-px-20 tw-bg-black/60 lg:tw-bg-transparent">
                    <div className="tw-flex tw-justify-between tw-items-center">
                        <div className="tw-flex tw-flex-col tw-text-white">
                            <NavLink to="/"
                                     className="navbar-brand tw-w-28 tw-h-28"> {/* className="navbar-brand tw-text-white hover:tw-text-primary tw-transition-colors tw-font-secondary tw-uppercase font-bold tw-text-3xl md:tw-text-5xl" */}
                                <img className="w-full h-full" src={lpss_logo} alt="logo"/>
                            </NavLink>
                            {/* <p className="tw-text-base tw-font-secondary">Giving is Living</p> */}
                        </div>
                        {/* <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                            <span className="navbar-toggler-icon tw-text-white"></span>
                        </button> */}
                        <div>
                            {
                                toggle ?
                                    <svg className=' lg:tw-hidden tw-block tw-w-7 tw-h-7 tw-fill-white'
                                         onClick={() => setToggle(!toggle)} stroke='white' floodColor={'#fffff'}
                                         xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                        <path
                                            d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                                    </svg>
                                    :
                                    <span className='tw-text-white'> <svg
                                        className='tw-fill-white lg:tw-hidden tw-block tw-w-7 tw-h-7'
                                        onClick={() => setToggle(!toggle)} xmlns="http://www.w3.org/2000/svg"
                                        height="1em" viewBox="0 0 448 512"><path
                                        d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/></svg></span>
                            }
                        </div>
                        <div className=" tw-text-white tw-hidden lg:tw-flex ">
                            <NavLink to="/" className="nav-item nav-link tw-text-lg">Home</NavLink>
                            <HashLink to="/#about_s" className="nav-item nav-link tw-text-lg ">About</HashLink>
                            <NavLink to="/causes" className="nav-item nav-link tw-text-lg ">Our Initiatives</NavLink>
                            {/*  <NavLink to="/lalpari_sewa_sadan"
                                        className="nav-item nav-link tw-font-bold tw-text-lg">LPS Sadan</NavLink>
                               <NavLink to="causes" className="nav-item nav-link ">Our Supporters</NavLink> */}
                            {/* <div className="nav-item dropdown">
                                    <NavLink to="" className="nav-link dropdown-toggle tw-text-lg"
                                            data-toggle="dropdown">Our Supporters</NavLink>
                                    <div className="dropdown-menu">
                                        <a href='https://www.aapkilathi.com/' className=" nav-link tw-text-lg" target="_blank"
                                        rel="noreferrer">Aapkilathi</a>
                                        <a href='https://workworkltd.com/' className=" nav-link tw-text-lg" target="_blank"
                                        rel="noreferrer">Work Work Ltd</a> */}
                            {/* <a href='/lalpari_sewa_sadan' className=" nav-link tw-text-lg" target="_blank"
                                        rel="noreferrer">LPS Sadan</a> */}
                            {/* </div>
                                </div> */}
                            {/* <a href="assets/Audited_financial.pdf" className="nav-item nav-link tw-text-lg "
                                target="_blank" rel="noreferrer">Audited Financials</a> */}
                            <NavLink to="/contact" className="nav-item nav-link tw-text-lg">Contact</NavLink>
                        </div>
                        {/* Responsive Navbar */}
                        <div
                            className={`tw-duration-300 lg:tw-hidden tw-w-fit tw-h-full tw-fixed tw-top-[88px] md:tw-top-[99px] tw-text-white tw-z-30 ${toggle ? 'tw-left-[0]' : 'tw-left-[-100%]'}`}>
                            <NavLink to="/" className="nav-item nav-link tw-text-lg tw-bg-accent ">Home</NavLink>
                            <NavLink to="/about"
                                     className="nav-item nav-link tw-text-lg tw-bg-accent my-1">About</NavLink>
                            <NavLink to="/causes" className="nav-item nav-link tw-text-lg tw-bg-accent">Our
                                Initiatives</NavLink>
                            {/* <NavLink to="/lalpari_sewa_sadan"
                                        className="nav-item nav-link tw-font-bold tw-text-lg tw-bg-accent my-1">LPS Sadan</NavLink> */}
                            {/* <NavLink to="causes" className="nav-item nav-link ">Our Supporters</NavLink> */}
                            {/* <div className="nav-item dropdown">
                                    <NavLink to="" className="nav-link dropdown-toggle tw-text-lg tw-bg-accent"
                                            data-toggle="dropdown">Our Supporters</NavLink>
                                    <div className="dropdown-menu">
                                        <a href='https://www.aapkilathi.com/' className=" nav-link tw-text-lg" target="_blank"
                                        rel="noreferrer">Aapkilathi</a>
                                        <a href='https://workworkltd.com/' className=" nav-link tw-text-lg" target="_blank"
                                        rel="noreferrer">Work Work Ltd</a>
                                    </div>
                                </div> */}
                            {/* <a href="assets/Audited_financial.pdf" className="nav-item nav-link tw-text-lg tw-bg-accent my-1"
                                target="_blank" rel="noreferrer">Audited Financials</a> */}
                            <NavLink to="/contact"
                                     className="nav-item nav-link tw-text-lg tw-bg-accent">Contact</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </Headroom>
    )
}

export default LPSSS