import React from "react";
import mapBg from '../../assets/map.png'
import {FaPhoneAlt, FaMapMarkerAlt, FaFacebook} from 'react-icons/fa'
import {MdEmail} from 'react-icons/md'
import {AiOutlineTwitter, AiFillLinkedin, AiOutlineWhatsApp, AiOutlineRight, AiFillYoutube} from 'react-icons/ai'


function Footer_main() {

    return (
        <div className="tw-relative tw-flex tw-flex-col tw-bg-dark tw-pt-10 tw-pb-2 tw-px-6 tw-overflow-hidden">
            <div className="tw-flex tw-w-full tw-relative tw-pointer-events-none">
                <div
                    className="tw-flex tw-w-full lg:tw-w-1/3 tw-left-1/2 lg:tw-left-0 -tw-translate-y-1/2 lg:-tw-translate-y-0 lg:-tw-translate-x-0 -tw-translate-x-1/2 tw-absolute tw-opacity-40 tw-px-6">
                    <img src={mapBg} className="tw-w-full tw-h-auto" alt=""/>
                </div>
            </div>
            <div className="tw-flex tw-w-full">
                <div className="w-full tw-flex tw-flex-col lg:tw-flex-row lg:tw-px-32 py-4">
                    {/* <div className="tw-flex tw-flex-col tw-w-full lg:tw-px-3 lg:tw-w-2/6">
                        <h2 className="tw-text-white tw-text-xl lg:tw-text-2xl">Our Supporters</h2>
                        <div className=" tw-flex tw-flex-col tw-justify-center md:tw-justify-start">
                           <div className="tw-w-28 tw-h-24 tw-py-2">
                                <a className="tw-cursor-pointer"  target="_blank" href="https://www.aapkilathi.com/"><img className="tw-w-full tw-h-full" src="/logo/logo.png" alt="Aapkilathi"/></a>
                           </div>
                            <div className="tw-w-52 tw-h-16 tw-py-2">
                                <a className="tw-cursor-pointer"  target="_blank" href="https://workworkltd.com/"><img className="tw-w-full tw-h-full" src="/logo/wwl_logo.png" alt="workWork"/></a>
                            </div>
                        </div>
                    </div> */}
                    <div className="tw-w-full tw-grid tw-grid-cols-2 lg:tw-grid-cols-4">
                        <div className="tw-flex tw-flex-col tw-text-white tw-w-full lg:tw-ml-3">
                            <h2 className="tw-text-white tw-text-lg lg:tw-text-2xl">Our Head Office</h2>
                            <p className="tw-text-xs md:tw-text-base tw-flex tw-items-center tw-mt-2">
                                <FaMapMarkerAlt className='lg:tw-h-8 lg:tw-w-8 tw-w-6 tw-h-6 tw-mr-2'/>
                                Debihtola, Madhubani, Bihar- 847404, India
                            </p>
                            <a id="call_btn"
                               className="tw-text-xs md:tw-text-base tw-flex tw-items-center tw-my-2"
                               href="tel:+917091804541">
                                <FaPhoneAlt className='lg:tw-h-6 lg:tw-w-6 tw-w-4 tw-h-4 tw-mr-2'/>+91 7091804541</a>
                            <p className="tw-text-xs md:tw-text-base tw-flex  tw-items-center tw-my-2">
                                <MdEmail className='lg:tw-h-8 lg:tw-w-8 tw-w-4 tw-h-4 tw-mr-2'/><span>
                                <a href="mailto:jhatara123@gmail.com">jhatara123@gmail.com</a>,<br/> <a
                                href="mailto:yadutrustorg@gmail.com">yadutrustorg@gmail.com</a>
                            </span>
                            </p>
                        </div>
                        <div className="tw-flex tw-flex-col tw-text-white lg:tw-pl-7 tw-w-full">
                            <h2 className="tw-text-white tw-text-lg lg:tw-text-2xl">Popular Links</h2>
                            <ul className="relative">
                                <a href=""><li className="tw-text-xs md:tw-text-base tw-flex tw-items-center tw-my-2"><AiOutlineRight />About Us</li></a>
                                <a href=""><li className='tw-text-xs md:tw-text-base tw-flex tw-items-center tw-my-2'><AiOutlineRight />Contact Us</li></a>
                                <a href=""><li className='tw-text-xs md:tw-text-base tw-flex tw-items-center tw-my-2'><AiOutlineRight />Popular Causes</li></a>
                            </ul>
                        </div>
                        <div className="tw-flex tw-flex-col lg:tw-pl-6 tw-text-white tw-w-full">
                            <h2 className="tw-text-white tw-text-lg lg:tw-text-2xl">Useful Links</h2>
                            <ul className="relative">
                                <a target="_blank" href="/termsofuse/"><li className="tw-text-xs md:tw-text-base tw-flex tw-items-center tw-my-2"><AiOutlineRight className=''/>Terms of use</li></a>
                                <a target="_blank" href="/privacy-policy/"><li className="tw-text-xs md:tw-text-base tw-flex tw-items-center tw-my-2"><AiOutlineRight className=''/>Privacy policy</li></a>
                                <a target="_blank" href="/refund-policy/"><li className="tw-text-xs md:tw-text-base tw-flex tw-items-center tw-my-2"><AiOutlineRight className=''/>Refund policy</li></a>
                            </ul>
                        </div>
                        <div className="tw-flex tw-flex-col lg:tw-px-6 tw-text-white tw-w-full">
                            <h2 className="tw-text-white tw-text-lg lg:tw-text-2xl">Follow Us</h2>
                            <div className="tw-flex tw-flex-row tw-justify-normal lg:tw-justify-between tw-gap-2 lg:tw-gap-0 tw-flex-wrap">
                                <a href='https://www.facebook.com/yadungo' className="tw-text-xs lg:tw-text-base tw-flex tw-items-center tw-my-2"><FaFacebook className='tw-h-6 tw-w-6'/></a>
                                <a href='https://wa.me/917091804541' className="tw-text-xs lg:tw-text-base tw-flex tw-items-center tw-my-2"><AiOutlineWhatsApp className='tw-h-6 tw-w-6'/></a>
                                <a href='https://www.linkedin.com/company/yadunandan-shiksha-seva-sansthan/?originalSubdomain=in' className="tw-text-xs lg:tw-text-base tw-flex tw-items-center tw-my-2"><AiFillLinkedin className='tw-h-6 tw-w-6'/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tw-flex tw-w-full tw-justify-center tw-items-center tw-text-center">
                <div className="tw-flex tw-justify-center tw-text-center tw-py-3 tw-border-t tw-text-primary">
                    <span>yadutrust &copy; All Rights Reserved</span>
                </div>
            </div>
        </div>
    )
}

export default Footer_main;
