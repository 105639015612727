import React, {useEffect, useRef, useState} from "react";
import map from "../../assets/map.png";
import turningPoint from '../../assets/slides/TurningPoint.jpg'
import turningPoint1 from '../../assets/slides/TurningPoint1.jpg'
import visionBirth from '../../assets/slides/VisionBirth.jpg'
import visionBirth1 from '../../assets/slides/VisionBirth1.jpg'
import adversities from '../../assets/slides/Adversities.jpg'
import adversities1 from '../../assets/slides/Adversities1.jpg'
import spirit from '../../assets/slides/Spirit.jpg'
import spirit1 from '../../assets/slides/Spirit1.jpg'
import healthCenter from '../../assets/slides/HealthCenter.jpg'
import healthCenter1 from '../../assets/slides/HealthCenter1.jpg'
import continues from '../../assets/slides/Continues.jpg'
import continues1 from '../../assets/slides/Continues1.jpg'
import {gsap} from "gsap";
import Carousel from "react-elastic-carousel";

const journey = [
    {
        title: "A Tragic Turning Point",
        paragraph: "It was a moment of profound sadness and loss that planted the seed for what would become the Lal Pari Swasthya Seva Sadan (LPS Sadan). Four years ago, our peaceful rural life was disrupted when we faced the devastating loss of our beloved mother to a stroke. With the nearest hospital a staggering 45 kilometers away and the lack of skilled healthcare personnel locally, time slipped away from us during the journey to the hospital in Darbhanga. The loss marked a dark day, but also kindled a fierce determination to change the face of healthcare accessibility in our village.",
        slides: [turningPoint, turningPoint1]
    },
    {
        title: "The Birth of a Vision",
        paragraph: "Out of personal tragedy sprang a vision potent with hope and resilience. We envisioned LPS Sadan to be the very essence of quality healthcare in the heart of Devhitol – a sanctuary where the dearth of immediate, affordable, and quality medical attention would no longer be a death sentence. This vision took tangible form on a piece of barren agricultural land, a blank canvas soon to be transformed into a vibrant hub of community health and wellbeing.",
        slides: [visionBirth, visionBirth1]
    },
    {
        title: "Overcoming Adversities",
        paragraph: "The path to realizing our dream wasn't easy. It was a journey dotted with skepticism and objections, a path where many laughed at the audacity of such an endeavor in a rural setting. But fueled by an unwavering resolve and a community rallying with us, we took each challenge in stride, converting every obstacle into a stepping stone, transforming each cynicism into an opportunity to redefine rural healthcare.",
        slides: [adversities, adversities1]
    },
    {
        title: "The Unyielding Spirit",
        paragraph: "Today, the LPS Sadan stands tall as a living testimony to our undying spirit and the robust support of our community. From a barren plot, has risen a health center that doesn't just treat illnesses but symbolizes hope and a steadfast commitment to the wellness of our people. It is a tangible manifestation of our belief that quality healthcare should be within everyone's reach, regardless of where they live.",
        slides: [spirit, spirit1]
    },
    {
        title: "A Trusted Primary Health Center",
        paragraph: "LPS Sadan is more than a healthcare facility; it has become a beacon of hope, a promise of a healthier tomorrow, and a realization of a community's dream. Throughout our journey, we've adhered to principles of affordability, accessibility, and sustainability, all converging to create a healthcare entity that fosters a positive and lasting impact on both individual lives and the environment.",
        slides: [healthCenter, healthCenter1]
    },
    {
        title: "Our Story Continues",
        paragraph: "The journey of LPS Sadan is far from over. It is a continually unfolding narrative of empowerment and wellbeing. Our doors are open, inviting everyone to be part of this transformative journey. To collaborate in creating a resilient rural community where quality healthcare is not a privilege, but a right. At LPS Sadan, our story is woven with threads of determination, unity, and an unyielding belief in the potential for change.\n" +
            "\n" +
            "As we stand amidst the lush greenery of our campus, we invite you to join us. To walk with us on a path that promises not just healing but empowerment. To contribute to a narrative where no life is lost due to a lack of accessible healthcare. Our story is far from over, and we eagerly anticipate the exciting, impactful chapters yet to be written, with you as part of our unfolding narrative.",
        slides: [continues, continues1]
    }
]

function LpssJourney() {
    const [activeJourney, setActiveJourney] = useState(0)

    const journeyClicked = (e, index) => {
        setActiveJourney(index)
    }


    useEffect(() => {

    }, []);
    return (
        <div
            className="tw-w-full tw-relative tw-flex tw-flex-col tw-px-4 md:tw-px-20 lg:tw-px-40 xl:tw-px-44 tw-pt-10 lg:tw-pt-24 tw-bg-[#E6F1F7]">
            <div
                className="tw-w-full tw-flex tw-flex-col tw-justify-end tw-py-6 tw-relative tw-z-20 xl:tw-w-1/2 tw-mr-auto">
                <h5 className="tw-text-accent tw-text-xl tw-font-bold tw-uppercase tw-mb-4">about LPS Sadan and its
                    journey</h5>
                <p className="tw-text-3xl md:tw-text-5xl tw-font-bold">A Beacon of Hope and Health in Devhitol</p>
            </div>
            <div className="tw-w-full tw-flex tw-flex-col-reverse xl:tw-flex-row">
                <div className="tw-w-full xl:tw-w-1/2 tw-align-bottom">
                    <div className="tw-w-full tw-relative tw-z-10">
                        <div
                            className="tw-absolute md:tw-top-1/2 tw-left-1/2 -tw-translate-y-1/2 -tw-translate-x-1/2 tw-w-full tw-z-0">
                            <img className="tw-opacity-100" src={map} alt=""/>
                        </div>

                        <div className="tw-relative tw-z-10 lg:tw-hidden tw-py-4">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                 viewBox="0 0 500 145.7" style={{enableBackground: 'new 0 0 500 145.7'}}
                                 xmlSpace="preserve">

                                <path style={{fill: 'none', stroke: '#282626', strokeWidth: 8.5233}}
                                      d="M49,72v52.7c0,5.8,4.7,10.4,10.4,10.4h295.9c5.8,0,10.4-4.7,10.4-10.4V90.9c0-5.8,4.7-10.4,10.4-10.4H500"/>
                                <path style={{
                                    fill: 'none',
                                    stroke: '#FFFFFF',
                                    strokeWidth: 0.4735,
                                    strokeDasharray: '1.8941,1.8941'
                                }}
                                      d="M49.2,72v52.7c0,5.8,4.7,10.4,10.4,10.4h295.7c5.8,0,10.4-4.7,10.4-10.4V90.9c0-5.8,4.7-10.4,10.4-10.4h123.1"
                                />
                                <g className="tw-cursor-pointer" onClick={(e) => journeyClicked(e, 0)}>
                                    <circle className="tw-drop-shadow" fill={activeJourney === 0 ? "#F66251" : "white"} cx="49" cy="64.9" r="8.5"/>
                                    <g fill={activeJourney === 0 ? "#F66251" : "#282626"} fontSize={11.3643}
                                       fontFamily="Josefin Sans">
                                        <text transform="matrix(1 0 0 1 27.8657 20.3487)">A
                                            Tragic
                                        </text>
                                        <text transform="matrix(1 0 0 1 15.2305 31.713)">Turning
                                            Point
                                        </text>
                                    </g>
                                </g>
                                <g onClick={(e) => journeyClicked(e, 1)}>
                                    <circle className="tw-drop-shadow" fill={activeJourney === 1 ? "#F66251" : "white"} cx="93" cy="135.4"
                                            r="8.5"/>
                                    <g fill={activeJourney === 1 ? "#F66251" : "#282626"} fontSize={11.3643}
                                       fontFamily="Josefin Sans">
                                        <text transform="matrix(1 0 0 1 57.8467 89.9557)">The
                                            Birth of a
                                        </text>
                                        <text transform="matrix(1 0 0 1 78.0454 101.3199)"
                                        >Vision
                                        </text>
                                    </g>
                                </g>
                                <g onClick={(e) => journeyClicked(e, 2)}>
                                    <circle className="tw-drop-shadow" fill={activeJourney === 2 ? "#F66251" : "white"} cx="187.2" cy="135.4"
                                            r="8.5"/>
                                    <g fill={activeJourney === 2 ? "#F66251" : "#282626"} fontSize={11.3643}
                                       fontFamily="Josefin Sans">
                                        <text transform="matrix(1 0 0 1 156.9854 89.9557)"
                                        >Overcoming
                                        </text>
                                        <text transform="matrix(1 0 0 1 160.3091 101.3199)"
                                        >Adversities
                                        </text>
                                    </g>
                                </g>
                                <g onClick={(e) => journeyClicked(e, 3)}>
                                    <circle className="tw-drop-shadow" fill={activeJourney === 3 ? "#F66251" : "white"} cx="296.6" cy="135.4"
                                            r="8.5"/>
                                    <g fill={activeJourney === 3 ? "#F66251" : "#282626"} fontSize={11.3643}
                                       fontFamily="Josefin Sans">
                                        <text transform="matrix(1 0 0 1 257.9902 89.9557)">The
                                            Unyielding
                                        </text>
                                        <text transform="matrix(1 0 0 1 282.6494 101.3199)"
                                        >Spirit
                                        </text>
                                    </g>
                                </g>
                                <g onClick={(e) => journeyClicked(e, 4)}>
                                    <circle className="tw-drop-shadow" fill={activeJourney === 4 ? "#F66251" : "white"} cx="365.8" cy="106.5"
                                            r="8.5"/>
                                    <g fill={activeJourney === 4 ? "#F66251" : "#282626"} fontSize={11.3643}
                                       fontFamily="Josefin Sans">
                                        <text transform="matrix(1 0 0 1 392.2754 106.5279)">A
                                            Trusted Primary
                                        </text>
                                        <text transform="matrix(1 0 0 1 392.2754 117.8932)"
                                        >Health Center
                                        </text>
                                    </g>
                                </g>
                                <g onClick={(e) => journeyClicked(e, 5)}>
                                    <circle className="tw-drop-shadow" fill={activeJourney === 5 ? "#F66251" : "white"} cx="435.4" cy="80.5"
                                            r="8.5"/>
                                    <g fill={activeJourney === 5 ? "#F66251" : "#282626"} fontSize={11.3643}
                                       fontFamily="Josefin Sans">
                                        <text transform="matrix(1 0 0 1 408.4102 43.5509)">Our
                                            Story
                                        </text>
                                        <text transform="matrix(1 0 0 1 405.6689 54.9152)"
                                        >Continues...
                                        </text>
                                    </g>
                                </g>
                                <path style={{
                                    fill: 'none',
                                    stroke: '#262626',
                                    strokeWidth: 0.4735,
                                    strokeLinecap: 'round'
                                }} d="M49.5,40.2v7.1"/>
                                <path style={{
                                    fill: 'none',
                                    stroke: '#262626',
                                    strokeWidth: 0.4735,
                                    strokeLinecap: 'round'
                                }} d="M93.7,109.4v7.1"/>
                                <path style={{
                                    fill: 'none',
                                    stroke: '#262626',
                                    strokeWidth: 0.4735,
                                    strokeLinecap: 'round'
                                }} d="M188.2,107.9v7.1"/>
                                <path style={{
                                    fill: 'none',
                                    stroke: '#262626',
                                    strokeWidth: 0.4735,
                                    strokeLinecap: 'round'
                                }} d="M296.6,109.4v7.1"/>
                                <path style={{
                                    fill: 'none',
                                    stroke: '#262626',
                                    strokeWidth: 0.4735,
                                    strokeLinecap: 'round'
                                }} d="M385.6,106.5h-7.1"/>
                                <path style={{
                                    fill: 'none',
                                    stroke: '#262626',
                                    strokeWidth: 0.4735,
                                    strokeLinecap: 'round'
                                }} d="M435.1,61.3v7.1"/>
                            </svg>
                        </div>

                        <div className="tw-relative tw-z-10 tw-hidden lg:tw-block">
                            <svg className="tw-w-full" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                 x="0px" y="0px"
                                 viewBox="0 0 300 282" style={{enableBackground: 'new 0 0 300 282'}}
                                 xmlSpace="preserve">
                                <g>
                                    <path style={{fill: "none", stroke: '#282626', strokeWidth: 6.9057}}
                                          d="M32.2,49.7v42.7c0,4.7,3.8,8.4,8.4,8.4h239.8c4.7,0,8.4,3.8,8.4,8.4V282"/>
                                    <path style={{
                                        fill: "none",
                                        stroke: '#FFFFFF',
                                        strokeWidth: 0.3837,
                                        strokeDasharray: "1.5346,1.5346"
                                    }}
                                          d="M32.4,49.7v42.7c0,4.7,3.8,8.4,8.4,8.4h239.6c4.7,0,8.4,3.8,8.4,8.4v172.9"/>
                                    <g className="tw-cursor-pointer" onClick={(e) => journeyClicked(e, 0)}>
                                        <circle className="tw-drop-shadow" fill={activeJourney === 0 ? "#F66251" : "white"} cx="32.4" cy="43.7"
                                                r="6.9"/>
                                    </g>
                                    <g className="tw-cursor-pointer" onClick={(e) => journeyClicked(e, 1)}>
                                        <circle className="tw-drop-shadow" fill={activeJourney === 1 ? "#F66251" : "white"} cx="84" cy="101.1"
                                                r="6.9"/>
                                    </g>
                                    <g className="tw-cursor-pointer" onClick={(e) => journeyClicked(e, 2)}>
                                        <circle className="tw-drop-shadow" fill={activeJourney === 2 ? "#F66251" : "white"} cx="164.2" cy="101.1"
                                                r="6.9"/>
                                    </g>
                                    <g className="tw-cursor-pointer" onClick={(e) => journeyClicked(e, 3)}>
                                        <circle className="tw-drop-shadow" fill={activeJourney === 3 ? "#F66251" : "white"} cx="244.4" cy="101.1"
                                                r="6.9"/>
                                    </g>
                                    <g className="tw-cursor-pointer" onClick={(e) => journeyClicked(e, 4)}>
                                        <circle className="tw-drop-shadow" fill={activeJourney === 4 ? "#F66251" : "white"} cx="288.9" cy="163.2"
                                                r="6.9"/>
                                    </g>
                                    <g className="tw-cursor-pointer" onClick={(e) => journeyClicked(e, 5)}>
                                        <circle className="tw-drop-shadow" fill={activeJourney === 5 ? "#F66251" : "white"} cx="288.9" cy="237.3"
                                                r="6.9"/>
                                    </g>
                                    <g>
                                        <g fill={activeJourney === 0 ? "#F66251" : "#282626"} fontSize={9.2076}
                                           fontFamily="Josefin Sans">
                                            <text transform="matrix(1 0 0 1 14.4334 7.8703)" >A
                                                Tragic
                                            </text>
                                            <text transform="matrix(1 0 0 1 3.0364 17.0783)" >Turning
                                                Point
                                            </text>
                                        </g>
                                    </g>
                                    <path style={{
                                        fill: 'none',
                                        stroke: '#262626',
                                        strokeWidth: 0.3837,
                                        strokeLinecap: 'round'
                                    }} d="M32.6,24v5.8"/>
                                    <g fill={activeJourney === 1 ? "#F66251" : "#282626"} fontSize={9.2076}
                                       fontFamily="Josefin Sans">
                                        <text transform="matrix(1 0 0 1 54.7811 64.2673)" >The Birth
                                            of a
                                        </text>
                                        <text transform="matrix(1 0 0 1 71.1458 73.4748)"
                                              >Vision
                                        </text>
                                    </g>
                                    <g fill={activeJourney === 2 ? "#F66251" : "#282626"} fontSize={9.2076}
                                       fontFamily="Josefin Sans">
                                        <text transform="matrix(1 0 0 1 138.9422 64.2673)"
                                              >Overcoming
                                        </text>
                                        <text transform="matrix(1 0 0 1 141.6346 73.4748)"
                                              >Adversities
                                        </text>
                                    </g>
                                    <g fill={activeJourney === 3 ? "#F66251" : "#282626"} fontSize={9.2076}
                                       fontFamily="Josefin Sans">
                                        <text transform="matrix(1 0 0 1 213.1048 64.2673)" >The
                                            Unyielding
                                        </text>
                                        <text transform="matrix(1 0 0 1 233.0843 73.4748)"
                                              >Spirit
                                        </text>
                                    </g>
                                    <path style={{
                                        fill: 'none',
                                        stroke: '#262626',
                                        strokeWidth: 0.3837,
                                        strokeLinecap: 'round'
                                    }} d="M83.9,80v5.8"/>
                                    <path style={{
                                        fill: 'none',
                                        stroke: '#262626',
                                        strokeWidth: 0.3837,
                                        strokeLinecap: 'round'
                                    }} d="M164.2,78.8v5.8"/>
                                    <path style={{
                                        fill: 'none',
                                        stroke: '#262626',
                                        strokeWidth: 0.3837,
                                        strokeLinecap: 'round'
                                    }} d="M244.4,80v5.8"/>
                                    <path style={{
                                        fill: 'none',
                                        stroke: '#262626',
                                        strokeWidth: 0.3837,
                                        strokeLinecap: 'round'
                                    }} d="M271.3,163.1h-5.8"/>
                                    <path style={{
                                        fill: 'none',
                                        stroke: '#262626',
                                        strokeWidth: 0.3837,
                                        strokeLinecap: 'round'
                                    }} d="M271.3,237.3h-5.8"/>
                                    <g fill={activeJourney === 4 ? "#F66251" : "#282626"} fontSize={9.2076}
                                       fontFamily="Josefin Sans">
                                        <text transform="matrix(1 0 0 1 176.1605 163.2497)" >A
                                            Trusted Primary
                                        </text>
                                        <text transform="matrix(1 0 0 1 192.4808 172.4567)" >Health
                                            Center
                                        </text>
                                    </g>
                                    <text fill={activeJourney === 5 ? "#F66251" : "#282626"} fontSize={9.2076}
                                          fontFamily="Josefin Sans"
                                          transform="matrix(1 0 0 1 170.4476 239.5964)" >Our Story
                                        Continues
                                    </text>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="tw-w-full tw-flex-1 tw-flex tw-py-6 tw-relative lg:tw-pl-20 tw-items-end">
                    <div className="tw-w-full tw-flex tw-flex-col">
                        <div className="tw-w-full tw-px-6">
                            <Carousel enableAutoPlay
                                      autoPlaySpeed={4000}
                                      removeArrowOnDeviceType={["tablet", "mobile"]}
                                      infinite={true}
                                      showArrows={false}
                                      pagination={false}
                            >
                                {
                                    journey[activeJourney].slides.map((slide, i) => <div
                                        className="tw-w-full tw-relative tw-h-64" key={i}>
                                        <img className="tw-w-full tw-h-full tw-object-cover tw-rounded-t-lg" src={slide}
                                             alt=""/>
                                    </div>)
                                }
                            </Carousel>
                        </div>
                        <div
                            className=" tw-bg-white tw-px-4 md:tw-px-12 tw-py-5 md:tw-py-10 tw-shadow-xl tw-rounded-b-lg">
                            <p className="tw-text-2xl tw-font-bold tw-relative tw-z-10 tw-mb-4 tw-text-center">
                                {journey[activeJourney].title}
                            </p>
                            <p className="tw-font-light tw-relative tw-z-10 tw-text-xs md:tw-text-sm lg:tw-text-xl tw-text-left md:tw-text-justify md:tw-mx-auto">
                                {journey[activeJourney].paragraph}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LpssJourney;