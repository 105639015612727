import React, { useEffect } from 'react'
import CausesItem from './CausesItem';

function Causes() {
    useEffect(() => {
        const body = document.querySelector('#root');
        body.scrollIntoView({
            behavior: 'smooth'
        }, 500)
    }, []);

const data=[
    {
        title:"Health for All",
        detail:"The Trust has taken this larger initiative to built a robust primary health care in the vicinity of the village. Now people in villages and rural areas will have a quick access to an affordable quality medical healthcare.",
        img:"assets/img/Banner6.jpg",
        path_action:{
            link: "/lalpari_sewa_sadan/"
        }
    },
    {
        title:"Education for All",
        detail:"In rural parts of India, children often lack access to quality education, which severely limits their future prospects. By supporting education through a charitable trust, we  help bridge this gap and provide opportunities for a better life.",
        img:"assets/img/Banner1.jpg",
        path_action: null
    },
    
    // {
    //     title:"Social Harmony",
    //     detail:"The trust built the 1st Goddess Durga temple in the village. Goddess Durga is the symbol of strength, religious harmony and strong cultural ethos.",
    //     img:"assets/img/causes2.jpg",
    // },
    {
        // title:"Environment",
        title:"Mother Nature",
        detail:"Green is the new colour of humanity- Firmly believing in this mantra , we have planted 1200 Mahogany tress in last 1 year. The greener our planet better our humanity and sustainability",
        img:"assets/img/Banner5.jpg",
        path_action: null
    }, 
    
    // {
    //     title:"Roots",
    //     detail:"An initiative under which the trust has built a common social engagement roof called Mandap. Seniors from the village assemble here for all key decisions targetted towards betterment of village as well a place for religious and cultural hangouts.",
    //     img:"assets/img/roots.png",
    // },
]
    return (
        <>
            <div className="causes">
                <div className="section-header text-center">
                    {/* <h2>Let's know about what we are doing</h2> */}
                    <h2>Areas of Impact</h2>
                </div>
                <div className="container-fluid">
                {data.map((item, index) => <CausesItem title={item.title} detail={item.detail} img={item.img} key={index}
                path_action = {item.path_action !== null ? item.path_action.link : null}
                
                /> )}
                </div>
            </div>
        </>
    )
}

export default Causes