import React, {useEffect, useRef} from "react";
import wheelChair_patient from '../../assets/nurse-pushing-a-patient-in-wheelchair.jpg'
import medical_personnel from '../../assets/medical_personnel.jpg'
import med_students from '../../assets/med_students.jpg'

function LpssStrategy() {

    useEffect(() => {

    }, []);
    return (
        <div
            className="tw-w-full tw-relative tw-flex tw-flex-col tw-px-4 md:tw-px-20 lg:tw-px-40 xl:tw-px-44 tw-py-10 lg:tw-py-24 tw-bg-dark tw-text-white">
            <div className="tw-w-full tw-flex tw-flex-col lg:tw-flex-row">
                <div className="tw-w-full lg:tw-w-2/5 xl:tw-pr-20 tw-flex tw-align-bottom">
                    <div className="tw-w-full tw-flex tw-flex-col tw-justify-end md:tw-py-6">
                        <h5 className="tw-text-accent tw-text-xl tw-font-bold tw-uppercase tw-mb-4">Our Approach</h5>
                        <p className="tw-text-3xl md:tw-text-5xl tw-font-bold">Approach and Community Impact.</p>
                    </div>
                </div>
                <div className="tw-w-full tw-flex-1 tw-py-4 lg:tw-py-6 tw-relative lg:tw-pl-20">
                    <span
                        className="tw-text-6xl lg:tw-text-8xl tw-absolute tw-text-white -tw-top-4 lg:tw-top-2 tw-left-10 lg:tw-left-20 -tw-translate-y-1/4  tw-z-0 tw-font-bold tw-opacity-10">
                        our strategy
                    </span>
                    <p className="tw-text-xl tw-font-bold tw-relative tw-z-10 tw-mb-6">
                        Expected Benefits for the community
                    </p>
                    <p className="tw-font-light tw-relative tw-z-10 tw-text-2xl">
                        Our project seeks to uplift the community by focusing on the following outcomes:
                    </p>
                </div>
            </div>
            <div className="tw-w-full tw-flex tw-flex-col "> {/*lg:tw-flex-row */}
                <div className="tw-w-full"> {/*lg:tw-pr-10 lg:tw-w-2/5 */}
                    <p className="tw-font-light tw-relative tw-z-10 tw-text-lg lg:tw-text-xl tw-mb-8">
                        At LPS Sadan, our approach is community-centric, with a strong focus on affordability,
                        accessibility, and preventive care. Our strategies are shaped by regular consultations and
                        feedback from the community, ensuring our services meet their specific needs. Collaborative
                        partnerships with local healthcare organizations, government agencies, and community leaders
                        form an integral part of our approach, guided by data-driven decision-making to foster
                        continuous improvement.
                    </p>
                    {/* <p className="tw-font-light tw-relative tw-z-10 tw-text-lg lg:tw-text-xl">
                        Through our concerted efforts, we aim to benefit over 2,000 residents of Devhitol village,
                        including 1,300 females and 700 males. Our approach encompasses providing employment
                        opportunities, skill development programs, awareness campaigns on preventive health, and
                        training for healthcare workers, fostering a healthier and more empowered community.
                    </p> */}
                </div>
                <div className="tw-w-full tw-flex-1 tw-mt-6 md:tw-mt-0 "> {/* lg:tw-pl-20 */}
                    <div className="tw-w-full">
                        <h5 className="tw-text-accent tw-text-xl tw-font-bold tw-uppercase tw-mb-4">Changing the
                            lives</h5>
                        <p className="tw-text-3xl md:tw-text-5xl tw-font-bold">A bridge over the existing healthcare</p>
                    </div>
                    <div className="tw-w-full tw-relative tw-grid tw-grid-rows md:tw-grid-cols-2 xl:tw-grid-cols-3 tw-gap-x-6 tw-mt-10">
                        <div className="tw-bg-white tw-rounded-lg tw-flex-col tw-px-2 tw-py-3 tw-text-center">
                            <h5 className="tw-text-xl tw-font-bold tw-mb-2 tw-text-complement">Healthcare Accessibility</h5>
                            <img className="tw-mx-auto tw-mb-2" src={wheelChair_patient} alt="Patient in wheelchair"/>
                            <p className="tw-font-thin tw-text-center tw-text-complement tw-leading-snug">
                                Easier access to essential healthcare services for all residents, Improved healthcare
                                accessibility and utilization of healthcare services.
                            </p>
                        </div>
                        <div className="tw-bg-white tw-rounded-lg tw-flex-col tw-px-2 tw-my-4 md:tw-my-0 tw-py-3 tw-text-center">
                            <h5 className="tw-text-xl tw-font-bold tw-mb-2 tw-text-complement">Community Engagement</h5>
                            <img className="tw-mx-auto tw-mb-2" src={medical_personnel} alt="Medical Personnel"/>
                            <p className="tw-font-thin tw-text-center tw-text-complement tw-leading-snug">
                                Increased participation in health education programs and campaigns, fostering a
                                well-informed and health-conscious community.
                            </p>
                        </div>
                        <div className="tw-bg-white tw-rounded-lg tw-flex-col tw-px-2 tw-py-3 md:tw-mt-4 xl:tw-mt-0 tw-text-center">
                            <h5 className="tw-text-xl tw-font-bold tw-mb-2 tw-text-complement">Skill Development & Employment</h5>
                            <img className="tw-mx-auto tw-mb-2" src={med_students} alt="Medical Students"/>
                            <p className="tw-font-thin tw-text-center tw-text-complement tw-leading-snug">
                                Opportunities for local residents in healthcare and related sectors, enhancing
                                livelihood prospects.
                            </p>
                        </div>
                    </div>
                    <p className="tw-font-light tw-relative tw-z-10 tw-text-lg lg:tw-text-xl tw-mt-6 md:tw-text-center">
                        We believe that this integrated approach will not only address immediate healthcare needs but also foster a resilient, healthy, and prosperous community, in line with our mission and vision.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default LpssStrategy;