import React from 'react'

function GovernanceItem(props) {
    return (
        <>
            {/* <div className="testimonial-item tw-max-w-md tw-mx-auto tw-bg-white tw-rounded-xl tw-shadow-md tw-overflow-hidden">
                <div className=" tw-flex tw-flex-col">
                    <div className="testimonial-profile tw-w-full tw-h-full">
                        <img src={props.img} alt="testimonial-logo" />
                    </div>
                    <div className="testimonial-name">
                        <h3>{props.name}</h3>
                        <p>{props.profession}</p>
                    </div>
                </div>
            </div> */}
                <div className="tw-relative tw-shadow-md tw-bg-slate-100 tw-my-4 xl:tw-my-0 tw-rounded tw-overflow-hidden">
                    <div className="tw-w-full tw-h-auto tw-flex lg:tw-flex-col tw-justify-center tw-overflow-hidden">
                        <img className="tw-w-2/5 lg:tw-w-full tw-h-[250px] lg:tw-h-[200px] xl: tw-object-cover" src={props.img} alt="testimonial-logo" />
                        <div className="tw-px-2 tw-py-4 tw-flex-1">
                            <h3 className="pb-1 tw-text-xl tw-font-semibold">{props.name}</h3>
                            <p className="pb-1 tw-text-sm">{props.profession}</p>
                        </div>
                    </div>    
                </div>
        </>
    )
}

export default GovernanceItem