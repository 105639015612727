import React from 'react'
import Middle2 from '../Component/Mddle2'
import Contact from '../Component/Contact'
function ContactPage() {
  return (
    <>
    <Middle2 title="Contact Us" page="Contact" img="assets/img/page-header1.jpg"/>
    <Contact/>
    </>
  )
}

export default ContactPage