import React, {useEffect, useRef} from "react";
import medical_bg from '../../assets/medical_bg.png'
import ambulance from '../../assets/icons/ambulance.png'
import diagnostic from '../../assets/icons/diagnostic.png'
import laboratory from '../../assets/icons/laboratory.png'
import med_bag from '../../assets/icons/med_bag.png'
import med_hands from '../../assets/icons/med_hands.png'
import online_consultations from '../../assets/icons/online_consultations.png'
import ot_bed from '../../assets/icons/ot_bed.png'
import pediatric from '../../assets/icons/pediatric.png'
import women_health from '../../assets/icons/women_health.png'
import community_hands from '../../assets/icons/community_hands.png'

const facilities = [
    {
        name: "Primary Care",
        icon: med_hands,
        caption: "Our primary healthcare facility offers consultations for general health issues, seasonal illnesses, and non-emergency medical conditions."
    },
    {
        name: "Women’s Health",
        icon: women_health,
        caption: "Specialized consultations focusing on women’s health, including prenatal and postnatal care, gynecological health, and family planning advice."
    },
    {
        name: "Pediatric Care",
        icon: pediatric,
        caption: "Dedicated pediatric services that focus on the healthcare needs of infants, children, and adolescents, including vaccinations and growth monitoring."
    },
    {
        name: "Diagnostic Tests",
        icon: diagnostic,
        caption: "Access to imaging services such as X-rays and ultrasounds, facilitating timely diagnosis and treatment."
    },
    {
        name: "Laboratory Tests",
        icon: laboratory,
        caption: "On-site laboratory equipped to perform basic blood tests, urine analysis, and other essential investigations."
    },
    {
        name: "Online Consultations",
        icon: online_consultations,
        caption: "Leveraging technology to connect rural patients with specialists in urban centers through telemedicine consultations, online Appointments, Online Test Reports."
    },
    {
        name: "Minors OT Surgeries",
        icon: ot_bed,
        caption: "Our operation theater is equipped to handle minor surgeries and procedures, ensuring quick intervention for non-complex medical conditions."
    },
    {
        name: "Community Engagement",
        icon: community_hands,
        caption: "Dedicated spaces for community engagement where regular health education sessions, workshops, and awareness campaigns are conducted."
    },
    {
        name: "Emergency Care",
        icon: med_bag,
        caption: "24/7 Emergency Services: Round-the-clock emergency services equipped to handle medical emergencies and provide immediate care."
    },
    {
        name: "Ambulance Service",
        icon: ambulance,
        caption: "Quick and efficient ambulance services to ensure safe and timely transportation of patients in critical conditions."
    }
]

function LpssFacilities() {

    useEffect(() => {

    }, []);
    return (
        <div
            className="tw-w-full tw-relative tw-flex tw-flex-col tw-px-4 md:tw-px-20 lg:tw-px-40 xl:tw-px-44 tw-py-10 lg:tw-py-24 tw-bg-white tw-text-complement tw-bg-center tw-bg-cover tw-bg-no-repeat tw-bg-opacity-60"
            style={{backgroundImage: `url(${medical_bg})`}}>
            <div className="tw-w-full tw-flex tw-flex-col tw-justify-end md:tw-py-6 tw-text-center">
                <h5 className="tw-text-accent tw-text-xl tw-font-bold tw-uppercase tw-mb-4">Facilities</h5>
                <p className="tw-text-3xl md:tw-text-5xl tw-font-bold">Facilities at LPS Sadan</p>
                <p className="tw-font-light tw-relative tw-z-10 tw-text-lg lg:tw-text-xl tw-mt-6 tw-text-center tw-w-full lg:tw-w-2/3 tw-mx-auto">
                    At Lal Pari Swasthya Seva Sadan, we are equipped to offer a wide range of facilities that cater to
                    the diverse healthcare needs of the rural population. Here is a detailed description of the
                    facilities available at our health center:
                </p>
            </div>
            <div
                className="tw-w-full tw-grid tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 xl:tw-grid-cols-5 tw-gap-2 md:tw-gap-4 lg:tw-gap-6 xl:tw-gap-10">
                {
                    facilities.map((facility, i) => <div className="tw-w-full tw-bg-white tw-text-center tw-shadow-md tw-rounded-md tw-px-2 md:tw-px-3 tw-py-4 tw-border" key={i}>
                        <img className="tw-mx-auto tw-w-1/3" src={facility.icon} alt={facility.name}/>
                        <h5 className="tw-text-xl tw-font-bold tw-mb-2 tw-text-complement">{facility.name}</h5>
                        <p className="tw-font-thin tw-text-center tw-text-complement tw-leading-snug tw-text-sm lg:tw-text-base">
                            {facility.caption}
                        </p>
                    </div>)
                }
            </div>
        </div>
    )
}

export default LpssFacilities;