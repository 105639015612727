import React from 'react'
function Team() {
    return (
        <>
            {/* <!-- Team Start --> */}
            <div className="team">
                <div className="container">
                    <div className="section-header text-center">
                        {/* <p>Meet the visionary</p> */}
                        <h2>Meet the visionary</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="team-item">
                                <div className="team-img">
                                    <img src="assets/img/tarakant.jpg" alt="Team-logo"  />
                                    {/* <img src="assets/img/AboutTeam.jpg" alt="Team-logo" style={{height:'100px'}}/> */}
                                </div>
                                <div className="team-text">
                                    <h2>Sh. Tara Kant Jha </h2>
                                    <p>Founder, Visionary and Social Crusader </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="team-about">
                                <h6>A septuagenarian, Mr. Jha has been a social crusader for many years. Society and people matter first - has been his mantra and also the objective of establishing Yadunandan Shiksha Seva Sansthan Trust. A visionary, under Mr. Jha’s leadership, motivation and guidance Yadu Trust has in past years since its inception has taken many causes including setting up en  <i>English Medium School for primary students</i>, building places of our traditional heritage and values including <i>Mandap and grand Goddess Durga Temple</i> and now setting up a <i>robust primary healthcare for all.</i> Believing in the philosophy of <i>- Giving is Living</i> , Mr. Jha continued to inspire and motivate the entire society</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Team End --> */}
        </>
    )
}

export default Team