import React from 'react'
import Middle2 from '../Component/Mddle2'
import About from '../Component/About'
import Service from '../Component/Service'
import Team from '../Component/Team'
import Governance from '../Component/Governance'
function AboutPage() {
  return (
    <>
        <Middle2 title="About us" page="About Us" img="assets/img/page-header1.jpg" />
        <About/>
        <Service/>
        <Team/>
        <Governance/>
    </>
  )
}

export default AboutPage