import React from 'react'
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";

function CausesItem(props) {
    return (
        <>
            {/* <div className="causes-item tw-bg-white tw-border tw-border-gray-200 tw-rounded-lg tw-shadow-xl tw-dark:bg-gray-800 tw-dark:border-gray-700 m-2 tw-group">
                <div className="causes-img">
                    <img src={props.img} alt="causes-logo" style={{ height: '250px' }} />
                </div>
                <div className="causes-text">
                    <h3>{props.title}
                   </h3>
                    <p>{props.detail}</p>
                    {
                        props.path_action != null &&
                        <Link className="tw-flex" to={props.path_action}> 
                            <span className="tw-border-b group-hover:tw-border-b-primary tw-font-semibold tw-duration-200">read more </span> <span className="tw-ml-1.5 tw-mt-1 group-hover:tw-ml-3.5 tw-duration-300"><BsFillArrowRightCircleFill className="tw-text-primary/90 group-hover:tw-text-primary/60 tw-h-5 tw-w-5"/></span>
                        </Link>
                    }
                    
                </div>
            </div> */}
        <div className="causes-item tw-bg-white tw-border tw-border-gray-200 tw-rounded-lg tw-shadow-xl tw-dark:bg-gray-800 tw-dark:border-gray-700 m-2">
            {
                        props.path_action != null ?
                        <Link to={props.path_action}> 
                            <div className="tw-group">
                                <div className="causes-img">
                                    <img src={props.img} alt="causes-logo" style={{ objectFit: 'cover', height: '250px' }} />
                                </div>
                                <div className="causes-text">
                                    <h3>{props.title}</h3>
                                    <p className="tw-text-[#777777]">{props.detail}</p>
                                    <div className="tw-flex tw-group"><span className=" group-hover:tw-border-b-primary tw-font-semibold tw-duration-300"><b>learn more</b> </span> <span className="tw-ml-1.5 tw-mt-1 group-hover:tw-ml-3.5 tw-duration-300"><BsFillArrowRightCircleFill className="tw-text-primary/90 hover:tw-text-primary/60 tw-h-5 tw-w-5"/></span></div>  
                                </div>
                             </div>
                        </Link>
                        :
                        <div>
                            <div className="causes-img">
                                <img src={props.img} alt="causes-logo" style={{ objectFit: 'cover', height: '250px' }} />
                            </div>
                            <div className="causes-text">
                                <h3>{props.title}
                            </h3>
                                <p>{props.detail}</p> 
                            </div>
                        </div>
            }
            </div>
        </>
    )
}

export default CausesItem