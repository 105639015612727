import React from 'react'
import { Link } from "react-router-dom";
import Carousel from 'react-elastic-carousel'
import '../style/style.css'
const breakPoints = [
    { width: 1, itemsToShow: 1 },
]
function Middle1() {
    return (
        <>
            {/* <!-- Carousel Start --> */}
            <div className="carousel">
                <Carousel breakPoints={breakPoints}
                    enableAutoPlay
                    autoPlaySpeed={4000}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    infinite={true}
                >
                    <div className="container-fluid ">
                        <div className="owl-carousel">
                            <div className="carousel-item">
                                <div className="carousel-img">
                                    <img src="assets/img/Banner1.jpg" alt="carousel" />
                                </div>
                                <div className="carousel-text">
                                    {/* <h1>Equal education means a better nation.</h1> */}
                                    <h1>Education for all</h1>
                                    <p>
                                        Education is the key to the locked doors of opportunity and a dignified life.
                                    </p>
                                    <div className="carousel-btn">
                                        <a className="btn btn-custom" href="https://pages.razorpay.com/yadutrust" target="_blank">Click here to make a Difference</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="container-fluid">
                        <div className="owl-carousel">
                            <div className="carousel-item">
                                <div className="carousel-img">
                                    <img src="assets/img/children.jpg" alt="carousel" />
                                </div>
                                <div className="carousel-text">
                                    <h1>Children are the future</h1>
                                    <p>
                                        Let’s embrace and spread Sanskrit education.
                                    </p>
                                    <div className="carousel-btn">
                                        <a className="btn btn-custom" href="#donate">Donate Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="container-fluid">
                        <div className="owl-carousel">
                            <div className="carousel-item">
                                <div className="carousel-img">
                                    <img src="assets/img/Banner4.jpg" alt="carousel" />
                                </div>
                                <div className="carousel-text">
                                    {/* <h1>Envirnoment</h1> */}
                                    <h1>Mother Nature</h1> 
                                    <p>Green is the new Humanity</p>
                                    <div className="carousel-btn">
                                        <a className="btn btn-custom" href="https://pages.razorpay.com/yadutrust" target="_blank">Click here to make a Difference</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="owl-carousel">
                            <div className="carousel-item">
                                <div className="carousel-img">
                                    {/* <img src="assets/img/causes1.jpg" alt="carousel" /> */}
                                    <img src="assets/img/Banner6.jpg" alt="carousel" />
                                </div>
                                <div className="carousel-text">
                                    {/* <h1>Health is crucial!</h1> */}
                                    <h1>Health for all</h1>
                                    <p>
                                        Let’s become the backbone of elderly people and help them to live a dignified life!
                                    </p>
                                    <div className="carousel-btn">
                                        <a className="btn btn-custom" target="_blank" href="/lalpari_sewa_sadan/">Learn More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Carousel>
            </div>
            {/* <!-- Carousel End --> */}
            {/* <!-- Video Modal Start--> */}
            <div className="modal fade" id="videoModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            {/* <!-- 16:9 aspect ratio --> */}
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe className="embed-responsive-item" title='youtube' src="" id="video" allowscriptaccess="always" allow="autoplay"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Video Modal End --> */}

            {/* <!-- Back to top button --> */}
            <Link to="#" className="back-to-top"><i className="fa fa-chevron-up"></i></Link>

            {/* <!-- Pre Loader --> */}
            {/* <div id="loader" className="show">
                <div className="loader"></div>
            </div> */}
        </>
    )
}

export default Middle1