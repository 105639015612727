import React, {useEffect, useRef} from "react";
import {gsap} from "gsap";
import helpImage from '../../assets/helppeople_image.png'
import patientArt from '../../assets/patient_doctor_art.jpg'

function LpssAbout() {

    useEffect(() => {

    }, []);
    return (
        <div className="tw-w-full tw-relative tw-px-4 md:tw-px-20 lg:tw-px-40 xl:tw-px-44 tw-py-10 lg:tw-py-24">
            <div className="tw-w-full tw-flex tw-flex-col lg:tw-flex-row">
                <div className="tw-w-full lg:tw-w-2/5 xl:tw-pr-24 tw-flex tw-align-bottom">
                    <div className="tw-w-full tw-flex tw-flex-col tw-justify-center md:tw-justify-end tw-py-6">
                        <h5 className="tw-text-accent tw-text-xl tw-font-bold tw-uppercase tw-mb-4">About LPS Sadan</h5>
                        <p className="tw-text-2xl md:tw-text-5xl tw-font-bold">Our Highest Ambition is to Help People.</p>
                    </div>
                </div>
                <div className="tw-w-full tw-flex-1 tw-flex-col lg:tw-py-6 tw-relative lg:tw-pl-20">
                    <span
                        className="tw-text-4xl lg:tw-text-6xl tw-absolute tw-text-gray-300 -tw-top-4 lg:tw-top-2 tw-left-10 lg:tw-left-auto lg:tw-right-10 tw-z-0 tw-font-bold tw-opacity-40">
                        About LPS Sadan
                    </span>
                    {/* <p className="tw-text-xl tw-font-bold tw-relative tw-z-10 tw-mb-6">
                        Our programs contribute to peace and development through volunteerism. Take part in changing the
                        lives of old people.
                    </p> */}
                    <p className="tw-font-light tw-relative tw-z-10 tw-text-lg lg:tw-text-xl">
                        At Lal Pari Swasthya Seva Sadan (LPS Sadan), an initiative proudly undertaken by Yadu Trust, we are
                        bridging healthcare divides prevalent in rural communities. It's our heartfelt endeavor to
                        make premium healthcare a fundamental right, accessible to every resident of the country.
                    </p>
                </div>
            </div>
            <div className="tw-w-full tw-flex tw-flex-col lg:tw-flex-row">
                <div className="tw-w-full lg:tw-w-2/5  lg:tw-pr-10 tw-my-10 lg:tw-my-0">
                    <div className="tw-w-full tw-px-12 tw-relative vertical_side_bars">
                        <img className="tw-w-full " src={helpImage} alt=""/>
                        <div
                            className="tw-absolute tw-bottom-4 tw-right-0 tw-w-1/2 p-2 tw-bg-white tw-rounded-md lg:tw-translate-x-1/3">
                            <img className="tw-rounded-md" src={patientArt} alt=""/>
                        </div>

                    </div>
                </div>
                <div className="tw-w-full tw-flex-1 tw-flex-row lg:tw-flex-col lg:tw-pl-20 tw-mt-10 md:tw-mt-20 lg:tw-mt-0">
                    <div className="tw-w-full">
                        <h5 className="tw-text-accent tw-text-xl tw-font-bold tw-uppercase tw-mb-4">Changing the
                            lives</h5>
                        <p className="tw-text-3xl md:tw-text-5xl tw-font-bold">A bridge over the existing healthcare</p>
                    </div>
                    <div className="tw-w-full tw-relative tw-mt-2 md:tw-mt-10 lg:tw-pl-6">
                        <p className="tw-text-2xl tw-font-bold tw-relative tw-z-10 md:tw-mb-3 tw-py-4">
                            <span className="tw-text-8xl tw-absolute tw-text-accent
                            tw-top-0 tw-left-4 lg:tw-left-0 tw-z-0 tw-font-bold tw-opacity-10 -tw-translate-x-1/3 -tw-translate-y-1/4">01</span>
                            Mission
                        </p>
                        <p className="tw-font-light tw-relative tw-z-10 tw-text-xl">
                            Our mission is straightforward yet vital - to forge a bridge over the existing healthcare
                            disparities in rural communities. At LPS Sadan, we firmly hold that premium healthcare is not a
                            luxury but a fundamental right. Our dedication fuels our journey in making this a tangible
                            reality for every inhabitant of Devhitol village, nestled in the Madhubani district of
                            Bihar.
                        </p>
                    </div>
                    <div className="tw-w-full tw-relative tw-mt-2 md:tw-mt-10 lg:tw-pl-6">
                        <p className="tw-text-2xl tw-font-bold tw-relative tw-z-10 md:tw-mb-3 tw-py-4">
                            <span className="tw-text-8xl tw-absolute tw-text-accent
                            tw-top-0 tw-left-5 lg:tw-left-0 tw-z-0 tw-font-bold tw-opacity-10 -tw-translate-x-1/3 -tw-translate-y-1/4">02</span>
                            Vision
                        </p>
                        <p className="tw-font-light tw-relative tw-z-10 tw-text-xl">
                            Our vision paints a picture of a thriving rural community where healthcare is not just
                            accessible and affordable, but primarily focuses on preventative measures. Our goal is to
                            enlighten individuals with crucial knowledge and present them with exhaustive healthcare
                            services, thus uplifting the overall well-being of the community.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LpssAbout;