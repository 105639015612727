import React, {useEffect, useRef, useState} from "react";
import blueBg from "../../assets/blue_bg.jpg";
import thank_you from "../../assets/thank_you.png";
import $ from "jquery";

function LpssContact() {
    const [activeJourney, setActiveJourney] = useState(0)

    const journeyClicked = (e, index) => {
        setActiveJourney(index)
    }
    const [contact, setContact] = useState({
        uid: "yadu",
        purpose: "enquiry",
        city: "",
        state: "",
        message: "",
        phone: "",
        name: "",
        dob: "",
    })
    const onSubmit = async event => {
        event.preventDefault();
        const data = {
            uid: "yadu",
            purpose: "volunteering",
            city: "",
            state: "",
            email: "notprovided@none.com",
            phone: contact.phone,
            message: "dob:" + contact.dob + ", Message: " + contact.message,
            name: contact.name,
        }
        const url = 'https://theroguepixxel.com/waapi/contact_submission'
        const requestOptions = {
            method: 'POST',
            mode: "no-cors",
            headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
            body: Object.entries(data).map(([k,v])=>{return k+'='+v}).join('&')
        };
        await fetch(url, requestOptions)
            .then(async response => {
                $('#success').html("<div class='alert alert-success'>");
                $('#success > .alert-success').html("<button type='button' class='close' data-dismiss='alert' aria-hidden='true'>&times;")
                    .append("</button>");
                $('#success > .alert-success')
                    .append("<strong>Your message has been sent. </strong>");
                $('#success > .alert-success')
                    .append('</div>');
            })
            .catch(async error => {
                $('#success').html("<div class='alert alert-danger'>");
                $('#success > .alert-danger').html("<button type='button' class='close' data-dismiss='alert' aria-hidden='true'>&times;")
                    .append("</button>");
                $('#success > .alert-danger').append($("<strong>").text("Sorry it seems that our server is not responding. Please try again later!"));
                $('#success > .alert-danger').append('</div>');
            });
    }



    const handleChange = (event) => {
        setContact(prev => ({...prev, [event.target.name]: event.target.value}));
    };


    useEffect(() => {

    }, []);
    return (
        <div
            className="tw-w-full tw-relative tw-flex tw-flex-col tw-px-4 md:tw-px-20 lg:tw-px-40 xl:tw-px-44 tw-py-2 lg:tw-py-24 tw-bg-cover tw-bg-center tw-bg-no-repeat tw-bg-opacity-70"
            style={{backgroundImage: `url(${blueBg})`}}>
            <div className="tw-w-full tw-flex tw-flex-col lg:tw-flex-row">
                <div className="tw-w-full lg:tw-w-1/2 tw-align-bottom">
                    <div className="tw-w-full tw-flex tw-flex-col tw-justify-end tw-py-3 md:tw-py-6">
                        {/* <h5 className="tw-text-accent tw-text-xl tw-font-bold tw-uppercase tw-mb-2 md:tw-mb-4">join us</h5> */}
                        <h5 className="tw-text-accent tw-text-xl tw-font-bold tw-uppercase tw-mb-2 md:tw-mb-4">Become A Volunteer</h5>
                        {/* <p className="tw-text-3xl md:tw-text-5xl tw-font-bold">On This Noble Journey</p> */}
                        <p className="tw-text-3xl md:tw-text-5xl tw-font-bold tw-justify-center">Helping needy people and children is the greatest act of service.</p>
                    </div>
                    {/* <p className="tw-font-light tw-relative tw-z-10 tw-text-lg lg:tw-text-xl md:tw-mt-2 ">
                        As we forge ahead, we recognize that the journey is far from over. The road ahead is paved with
                        opportunities and challenges alike. Our vision for LPS Sadan goes beyond its physical boundaries. We
                        aspire to be the beacon that lights up numerous other paths leading to rural development and
                        prosperity.
                    </p>
                    <p className="tw-font-light tw-relative tw-z-10 tw-text-lg lg:tw-text-xl tw-mt-2 ">
                        We invite you to join hands with us, to be a part of this revolutionary journey. Your support,
                        encouragement, and participation can amplify the impact we aim to create, fostering a network of
                        community health centers that stand as pillars of sustainability, compassion, and excellence.
                    </p>
                    <p className="tw-font-light tw-relative tw-z-10 tw-text-lg lg:tw-text-xl tw-mt-2 ">
                        Whether it's through volunteering, partnering with us for sustainable initiatives, or simply
                        spreading the word about our mission, there are numerous ways to contribute. Together, we can
                        sculpt a future where healthcare is not just a privilege but a right accessible to all,
                        regardless of where they reside.
                    </p>
                    <p className="tw-font-light tw-relative tw-z-10 tw-text-lg lg:tw-text-xl tw-mt-2 ">
                        As you traverse this journey with us, we promise, it will be one filled with discovery, growth,
                        and the collective joy of witnessing a community transform, flourish, and soar to new heights.
                    </p> */}
                    <p className="tw-font-light tw-relative tw-z-10 tw-text-lg lg:tw-text-xl tw-mt-2 ">
                    You can join us in addressing the root causes of the issues if you wish to contribute to the welfare of humanity. Your life is commendable for the humanitarian work you will do.
                    </p>
                </div>
                <div className="tw-w-full tw-flex-1 tw-flex tw-py-6 tw-relative lg:tw-pl-20 tw-items-end">
                    <form id="volunteer_form" className="tw-bg-white tw-rounded-lg tw-py-12 tw-px-8 tw-shadow-lg" onSubmit={onSubmit}>
                        <div className="tw-w-full">
                            <h5 className="tw-text-2xl tw-font-bold tw-relative tw-z-10 tw-mb-4 tw-text-center">
                                Become A Volunteer
                            </h5>
                            <p className="tw-w-full tw-font-light tw-relative tw-z-10 tw-text-base lg:tw-text-lg tw-text-justify tw-mx-auto">
                                “At Lal Pari Swasthya Seva Sadan, we are not just building a healthcare center; we are
                                nurturing dreams, fostering hope, and sculpting a healthier, happier, and harmonious
                                tomorrow.”
                            </p>
                        </div>

                        <div className="tw-w-full tw-mt-6">
                            <div className="tw-flex tw-flex-col lg:tw-flex-row tw-gap-y-4 lg:tw-gap-y-0 lg:tw-gap-x-6 tw-py-2">
                                <div className="tw-flex-1">
                                    <label htmlFor="name" className="block text-sm font-medium text-slate-700 tw-mb-0">
                                        Your Name
                                    </label>
                                    <div className="mt-1">
                                        <input type="text" name="name" id="name"
                                               required={true}
                                               onChange={handleChange}
                                               className="tw-px-3 tw-py-3 tw-bg-white tw-border tw-shadow-sm
                                           tw-border-complement/30 tw-placeholder-dark/40 focus:tw-outline-none
                                           focus:tw-border-accent focus:tw-ring-accent tw-block tw-w-full tw-rounded
                                           sm:tw-text-sm focus:tw-ring-1 contrast-more:tw-border-complement
                                           contrast-more:tw-placeholder-complement" placeholder="Your Full Name"/>
                                    </div>
                                </div>
                                <div className="tw-flex-1">
                                    <label htmlFor="email" className="block text-sm font-medium text-slate-700 tw-mb-0">
                                        Your Email Id
                                    </label>
                                    <div className="mt-1">
                                        <input type="email" name="email" id="email"
                                               required={true}
                                               onChange={handleChange}
                                               className="tw-px-3 tw-py-3 tw-bg-white tw-border tw-shadow-sm
                                           tw-border-complement/30 tw-placeholder-dark/40 focus:tw-outline-none
                                           focus:tw-border-accent focus:tw-ring-accent tw-block tw-w-full tw-rounded
                                           sm:tw-text-sm focus:tw-ring-1 contrast-more:tw-border-complement
                                           contrast-more:tw-placeholder-complement" placeholder="Your E-Mail Id"/>
                                    </div>
                                </div>
                            </div>

                            <div className="tw-flex tw-flex-col lg:tw-flex-row tw-gap-y-4 lg:tw-gap-y-0 lg:tw-gap-x-6 tw-py-2">
                                <div className="tw-flex-1">
                                    <label htmlFor="phone" className="block text-sm font-medium text-slate-700 tw-mb-0">
                                        Your Number
                                    </label>
                                    <div className="mt-1">
                                        <input type="phone" name="phone" id="phone"
                                               required={true}
                                               onChange={handleChange}
                                               className="tw-px-3 tw-py-3 tw-bg-white tw-border tw-shadow-sm
                                           tw-border-complement/30 tw-placeholder-dark/40 focus:tw-outline-none
                                           focus:tw-border-accent focus:tw-ring-accent tw-block tw-w-full tw-rounded
                                           sm:tw-text-sm focus:tw-ring-1 contrast-more:tw-border-complement
                                           contrast-more:tw-placeholder-complement" placeholder="Mobile Number"/>
                                    </div>
                                </div>
                                <div className="tw-flex-1">
                                    <label htmlFor="dob" className="block text-sm font-medium text-slate-700 tw-mb-0">
                                        Your Date of Birth
                                    </label>
                                    <div className="mt-1">
                                        <input type="date" name="dob" id="dob"
                                               required={true}
                                               onChange={handleChange}
                                               className="tw-px-3 tw-py-3 tw-bg-white tw-border tw-shadow-sm
                                           tw-border-complement/30 tw-placeholder-dark/40 focus:tw-outline-none
                                           focus:tw-border-accent focus:tw-ring-accent tw-block tw-w-full tw-rounded
                                           sm:tw-text-sm focus:tw-ring-1 contrast-more:tw-border-complement
                                           contrast-more:tw-placeholder-complement" placeholder="dd/mm/yy"/>
                                    </div>
                                </div>
                            </div>

                            <div className="tw-flex tw-flex-col lg:tw-flex-row tw-gap-y-4 lg:tw-gap-y-0 lg:tw-gap-x-6 tw-py-2">
                                <div className="tw-flex-1">
                                    <label htmlFor="message" className="block text-sm font-medium text-slate-700 tw-mb-0">
                                        Your Message
                                    </label>
                                    <div className="mt-1">
                                        <textarea name="message" id="message"
                                                  required={true}
                                                  onChange={handleChange}
                                                  className="tw-px-3 tw-py-3 tw-bg-white tw-border tw-shadow-sm
                                           tw-border-complement/30 tw-placeholder-dark/40 focus:tw-outline-none
                                           focus:tw-border-accent focus:tw-ring-accent tw-block tw-w-full tw-rounded
                                           sm:tw-text-sm focus:tw-ring-1 contrast-more:tw-border-complement
                                           contrast-more:tw-placeholder-complement" placeholder="Share your views, thoughts." rows={5}/>
                                    </div>
                                </div>
                            </div>

                            <div className="tw-flex lg:tw-gap-x-6 tw-py-2">
                                <div className="tw-flex">
                                    <div className="tw-relative tw-border tw-border-accent tw-border-dotted tw-rounded tw-ml-1.5 tw-mt-1">
                                        <button className="tw-relative -tw-left-1.5 -tw-top-1 hover:tw-top-0 hover:tw-left-0 tw-transition-all tw-rounded tw-px-8 tw-pb-3 tw-pt-3.5 tw-bg-accent tw-text-white tw-text-sm">Send Message</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div id="success" style={{position: "relative"}}></div>
                    </form>
                </div>
            </div>
            {/* <img className="tw-w-full lg:tw-w-1/4 tw-mx-auto tw-mt-3" src={thank_you} alt=""/> */}
        </div>
    )
}

export default LpssContact;