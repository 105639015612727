import React, { useState } from 'react'
import { Parallax } from 'react-parallax';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import axios from "axios";
function Donate() {
    const [values, setValues] = useState({
        name: '',
        email: '',
        countryCode: '+91',
        phoneNumber: "",
        amount: 1
    })
    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement('script')
            script.src = src

            script.onload = () => {
                resolve(true)
            }

            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script)
        })
    }

    const displayRazorpay = async (amount,name,email,countryCode,phoneNumber) => {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
        if (!res) {
            alert("You are Offline.....Failed to load Razorpay SDK")
            return
        }

        const options = {
            key: "rzp_live_EtPYdmsHjlQcaW", // Enter the Key ID generated from the Dashboard
            amount: amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: "INR",
            name: "Yadu Charitables",
            description: "Thanks for donation",
            // image: "https://example.com/your_logo",
            // order_id: "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            callback_url: "https://eneqd3r9zrjok.x.pipedream.net/",
            prefill: {
                "name": name,
                "email": email,
                "contact": countryCode+' '+phoneNumber
            },
            notes: {
                "address": "Razorpay Corporate Office"
            },
            theme: {
                "color": "#3399cc"
            },
            handler: function (response) {
                alert(response.razorpay_payment_id);
                alert("Payment is successful")
            }
        }

        const paymentObject = new window.Razorpay(options)
        paymentObject.open()
    }

    const handleOnChange = (e) => {
        const { name, value } = e.target
        setValues({ ...values, [name]: value })
    }

    const handleChange1 = (value, data) => {
        setValues(values => ({ ...values, phoneNumber: value.slice(data.dialCode.length), countryCode: '+' + data.dialCode }));
    }
    function handleSubmit(event) {
        event.preventDefault();

        const options = {
            method: 'post',
            url: 'https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay',
            headers: {
                accept: 'text/plain',
                'Content-Type': 'application/json',
                'X-VERIFY': ''
            },
            data: {
                "merchantId": "PGTESTPAYUAT",
                "merchantTransactionId": "MT7850590068188104",
                "merchantUserId": "MUID123",
                "amount": 10000,
                "redirectUrl": "http://localhost:3000/#donate",
                "redirectMode": "REDIRECT",
                "callbackUrl": "http://localhost:3000/#donate",
                "mobileNumber": "9999999999",
                "paymentInstrument": {
                    "type": "PAY_PAGE"
                }
            }
        };
        axios.request(options)
            .then(function (response) {
                console.log(response.data);
            })
            .catch(function (error) {
                console.error(error);
            });

        fetch('https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay', options)
            .then(response => response.json())
            .then(response => console.log(response))
            .catch(err => console.error(err));
        // console.log(values);
        // window.PhonePeCheckout.transact({ tokenUrl: "https://apps-uat.phonepe.com/v3/service/auth/access", type: "IFRAME" });

        // displayRazorpay(values.amount,values.name,values.email,values.countryCode,values.phoneNumber);

    }
    return (
        <>
            <Parallax blur={0} bgImage="assets/img/donate.jpg" bgImageAlt="the cat" strength={400} bgImageStyle={{ minHeight: "400px", width: "100%", }}>
                <div className="donate" id='donate'>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-8">
                                <div className="donate-content">
                                    <div className="section-header">
                                        <p><a href="https://pages.razorpay.com/yadutrust" target="_blank">Donate</a></p>
                                        <h2>Let's donate to the people in need for better lives</h2>
                                    </div>
                                    {/* <div className="donate-text">
                                <p>
                                    Lorem ipsum dolor sit amet elit. Phasellus nec pretium mi. Curabitur facilisis ornare velit non. Aliquam metus tortor, auctor id gravida, viverra quis sem. Curabitur non nisl nec nisi maximus. Aenean convallis porttitor. Aliquam interdum at lacus non blandit.
                                </p>
                            </div> */}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="donate-form">
                                    <form onSubmit={handleSubmit}>
                                        {/* <div className="control-group">
                                            <input type="text" className="form-control" value={values.name} placeholder="Name" name="name" required="required" onChange={(e) => handleOnChange(e)} />
                                        </div>
                                        <div className="control-group">
                                            <input type="email" className="form-control" value={values.email} placeholder="Email" name="email" required="required" onChange={(e) => handleOnChange(e)} />
                                        </div> */}
                                        {/* <div className="control-group">
                                            <input type="contact" className="form-control" value={values.contact} placeholder="Contact" name="contact" required="required" onChange={(e) => handleOnChange(e)} />
                                        </div> */}
                                     {/*   <PhoneInput
                                            containerStyle={{ marginBottom: '12px' }}
                                            buttonStyle={{ background: "none", paddingRight: "0px", borderRight: 'unset', borderColor: 'white' }}
                                            inputStyle={{ width: "100%", paddingLeft: "45px" }}
                                            country={'in'}
                                            inputProps={{
                                                name: 'phone',
                                                required: true,
                                                autoFocus: true
                                            }}

                                            onChange={handleChange1}
                                        />
                                                
                                        <div className="control-group">
                                            <input type="text" className="form-control" placeholder="Amount" name="amount" required="required" onChange={(e) => handleOnChange(e)} />
                                        </div>

                                         <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                    <label className="btn btn-custom active">
                                        <input type="radio" name="options" checked /> $10
                                    </label>
                                    <label className="btn btn-custom">
                                        <input type="radio" name="options" /> $20
                                    </label>
                                    <label className="btn btn-custom">
                                        <input type="radio" name="options" /> $30
                                    </label>
                                </div> */}
                                        <div>
                                            <a className="btn btn-custom" href="https://pages.razorpay.com/yadutrust" target="_blank" >Click here to make a Difference</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Parallax>
        </>
    )
}

export default Donate