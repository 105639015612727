import React,{useState} from 'react'
import { Parallax } from 'react-parallax'
import api from '../Service/api';

function Volunteer() {
    const [data, setData] = useState({
        id: "3fa85f64-5717-4562-b3fc-2c963f66afa29",
        name: "",
        email: "",
        phone: "",
        whatsApp: "",
        motive: ""
    });

    const handleChange = (event) => {
        setData(email => ({ ...email, [event.target.name]: event.target.value }));
    };

function handleSubmit(event) {
    event.preventDefault();
    console.log(data);
    api.post('/yadu/Volunteer/AddVolunteer', data)
        .then((res) => {
            console.log(res);
        })
        .catch((error) => {
            console.log(error);
        })

}


  return (
    <>
            {/* <!-- Volunteer Start --> */}

    {/* <Parallax blur={0} bgImage="assets/img/volunteer1.jpg" bgImageAlt="the cat" strength={400}> */}
    {/* <Parallax bgImage="assets/img/volunteer1.jpg" bgImageAlt="the cat"> */}
        <div className="volunteer">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-lg-7">
                        <div className="volunteer-content">
                            <div className="section-header">
                                <p>Become A Volunteer</p>
                                <h2>Helping needy people and children is the greatest act of service.</h2>
                            </div>
                            <div className="volunteer-text">
                                <p>
                                You can join us in addressing the root causes of the issues if you wish to contribute to the welfare of humanity. Your life is commendable for the humanitarian work you will do.                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        {/* <div className="volunteer-form">
                            <form onSubmit={handleSubmit}>
                                <div className="control-group">
                                    <input type="text" className="form-control" placeholder="Name" name='name' value={data.name} required="required" onChange={handleChange}/>
                                </div>
                                <div className="control-group">
                                    <input type="email" className="form-control" placeholder="Email" name='email' value={data.email} required="required" onChange={handleChange}/>
                                </div>
                                <div className="control-group">
                                    <input type="text" className="form-control" placeholder="Mob Number" name='phone' value={data.phone} required="required" onChange={handleChange}/>
                                </div>
                                <div className="control-group">
                                    <input type="text" className="form-control" placeholder="WhatsApp Number" name='whatsApp' value={data.whatsApp} required="required" onChange={handleChange}/>
                                </div>
                                <div className="control-group">
                                    <textarea className="form-control" placeholder="Why you want to become a volunteer?" name='motive' value={data.motive} required="required" onChange={handleChange}></textarea>
                                </div>
                                <div>
                                    <button className="btn btn-custom" type="submit">Become a volunteer</button>
                                </div>
                            </form>
                        </div> */}

                        {/* <!-- New volunteer-form --> */}
                        <div className="tw-bg-white tw-border tw-p-8 tw-shadow-lg">
                            <div className="tw-w-full">
                                <h5 className="tw-text-2xl tw-font-bold tw-text-[#FDBE33] tw-relative tw-mb-4 tw-text-center">
                                    Become A Volunteer
                                </h5>
                                {/* <p className="tw-w-full tw-font-light tw-relative tw-text-base lg:tw-text-lg tw-text-justify tw-mx-auto">
                                    “At Lal Pari Swasthya Seva Sadan, we are not just building a healthcare center; we are
                                    nurturing dreams, fostering hope, and sculpting a healthier, happier, and harmonious
                                    tomorrow.”
                                </p> */}
                            </div>
                            <div className="tw-w-full tw-mt-6">
                                <div className="tw-flex tw-flex-col lg:tw-flex-row tw-gap-y-4 lg:tw-gap-y-0 lg:tw-gap-x-6 tw-py-2">
                                    <div className="tw-flex-1">
                                        <label htmlFor="name" className="block text-sm font-medium text-slate-700 tw-mb-0">
                                            Your Name
                                        </label>
                                        <div className="mt-1">
                                            <input type="text" name="name" id="name"
                                                className="tw-px-3 tw-py-3 tw-bg-white tw-border tw-shadow-sm
                                            tw-border-complement/30 tw-placeholder-dark/40 focus:tw-outline-none
                                            focus:tw-border-[#FDBE33] focus:tw-ring-[#FDBE33] tw-block tw-w-full tw-rounded
                                            sm:tw-text-sm focus:tw-ring-1 contrast-more:tw-border-complement
                                            contrast-more:tw-placeholder-complement" placeholder="Your Full Name"/>
                                        </div>
                                    </div>
                                    <div className="tw-flex-1">
                                        <label htmlFor="email" className="block text-sm font-medium text-slate-700 tw-mb-0">
                                            Your Email Id
                                        </label>
                                        <div className="mt-1">
                                            <input type="email" name="email" id="email"
                                                className="tw-px-3 tw-py-3 tw-bg-white tw-border tw-shadow-sm
                                            tw-border-complement/30 tw-placeholder-dark/40 focus:tw-outline-none
                                            focus:tw-border-[#FDBE33] focus:tw-ring-[#FDBE33] tw-block tw-w-full tw-rounded
                                            sm:tw-text-sm focus:tw-ring-1 contrast-more:tw-border-complement
                                            contrast-more:tw-placeholder-complement" placeholder="Your E-Mail Id"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="tw-flex tw-flex-col lg:tw-flex-row tw-gap-y-4 lg:tw-gap-y-0 lg:tw-gap-x-6 tw-py-2">
                                    <div className="tw-flex-1">
                                        <label htmlFor="phone" className="block text-sm font-medium text-slate-700 tw-mb-0">
                                            Your Number
                                        </label>
                                        <div className="mt-1">
                                            <input type="phone" name="phone" id="phone"
                                                className="tw-px-3 tw-py-3 tw-bg-white tw-border tw-shadow-sm
                                            tw-border-complement/30 tw-placeholder-dark/40 focus:tw-outline-none
                                            focus:tw-border-[#FDBE33] focus:tw-ring-[#FDBE33] tw-block tw-w-full tw-rounded
                                            sm:tw-text-sm focus:tw-ring-1 contrast-more:tw-border-complement
                                            contrast-more:tw-placeholder-complement" placeholder="Mobile Number"/>
                                        </div>
                                    </div>
                                    <div className="tw-flex-1">
                                        <label htmlFor="dob" className="block text-sm font-medium text-slate-700 tw-mb-0">
                                            Your Date of Birth
                                        </label>
                                        <div className="mt-1">
                                            <input type="date" name="dob" id="dob"
                                                className="tw-px-3 tw-py-3 tw-bg-white tw-border tw-shadow-sm
                                            tw-border-complement/30 tw-placeholder-dark/40 focus:tw-outline-none
                                            focus:tw-border-[#FDBE33] focus:tw-ring-[#FDBE33] tw-block tw-w-full tw-rounded
                                            sm:tw-text-sm focus:tw-ring-1 contrast-more:tw-border-complement
                                            contrast-more:tw-placeholder-complement" placeholder="dd/mm/yy"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="tw-flex tw-flex-col lg:tw-flex-row tw-gap-y-4 lg:tw-gap-y-0 lg:tw-gap-x-6 tw-py-2">
                                    <div className="tw-flex-1">
                                        <label htmlFor="message" className="block text-sm font-medium text-slate-700 tw-mb-0">
                                            Your Message
                                        </label>
                                        <div className="mt-1">
                                            <textarea name="message" id="message"
                                                className="tw-px-3 tw-py-3 tw-bg-white tw-border tw-shadow-sm
                                            tw-border-complement/30 tw-placeholder-dark/40 focus:tw-outline-none
                                            focus:tw-border-[#FDBE33] focus:tw-ring-[#FDBE33] tw-block tw-w-full tw-rounded
                                            sm:tw-text-sm focus:tw-ring-1 contrast-more:tw-border-complement
                                            contrast-more:tw-placeholder-complement" placeholder="Share your views, thoughts." rows={5}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="tw-flex lg:tw-gap-x-6 tw-py-2">
                                    <div className="tw-flex">
                                        <div className="tw-relative tw-border tw-border-[#FDBE33] tw-border-dotted tw-rounded tw-ml-1.5 tw-mt-1">
                                            <button className="tw-relative -tw-left-1.5 -tw-top-1 hover:tw-top-0 hover:tw-left-0 tw-transition-all tw-rounded tw-px-8 tw-pb-3 tw-pt-3.5 tw-bg-[#FDBE33] tw-text-white tw-text-sm">Send Message</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* <!-- End New volunteer-form--> */}

                    </div>
                    
                </div>
            </div>
        </div>
        {/* </Parallax> */}
        {/* <!-- Volunteer End --> */}
    </>
  )
}

export default Volunteer