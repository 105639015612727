import React from 'react'
import GovernanceItem from './GovernanceItem'
const data=[
    {
        name:"Mr. Arvind Shahi",
        profession:"Chief Risk Officer of a large bank",
        img:"assets/img/arvindshahi.jpg",
    },
    {
        name:"Mr. Bipin Jha",
        profession:"Evangelist and Chairman of WorkWork Limited, United Kingdom",
        img:"assets/img/bipinjha.jpg",
    },
    {
        name:"Mr. Binay Jha",
        profession:"Co- Founder and CEO of Aapkilathi",
        img:"assets/img/binayjha.jpg",
    },

    {
        name:"Councillor Bhagwanji Chohan",
        profession:"Mentor",
        img:"assets/img/Bhagwanji_Chohan.jpg",
    },
    // {
    //     name:"Mr. Manoj Kumar",
    //     profession:"IT and Support",
    //     img:"assets/img/manoj.png",
    // },
    // {
    //     name:"Mr. Bam Boli",
    //     profession:"Teacher",
    //     img:"assets/img/bam.png",
    // },
    // {
    //     name:"Mr. Satish Chandra",
    //     profession:"Admin",
    //     img:"assets/img/satish.jpg",
    // },
]
function Governance() {
    return (
        <>
            {/* <div className="testimonial">
                <div className="container-fluid">
                    <div className="section-header text-center">
                        <p>Governance</p>
                    </div>
                    <div className="tw-flex tw-w-full tw-h-full"> {/*owl-carousel testimonials-carousel  */}
                    {/* {data.map((item, index) => <GovernanceItem name={item.name} profession={item.profession} img={item.img} key={index}/> )}
                    </div>
                </div>
            </div>  */}
            <div className="testimonial">
                <div className="container-fluid">
                    <div className="section-header text-center">
                        <p>Governance</p>
                    </div>
                    <div className=" tw-w-full tw-h-full tw-grid tw-grid-rows md:tw-grid-cols-2 lg:tw-grid-cols-4 xl:tw-grid-cols-4 md:tw-gap-x-6 lg:tw-gap-x-10 xl:tw-gap-x-16 2xl:tw-gap-x-28 "> {/*owl-carousel testimonials-carousel  */}
                    {data.map((item, index) => <GovernanceItem name={item.name} profession={item.profession} img={item.img} key={index}/> )}
                    </div>
                </div>
            </div> 
        </>
    )
}

export default Governance