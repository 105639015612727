import React from 'react'
import Middle2 from '../Component/Mddle2'

function NoPage() {
  return (
    <>
    <Middle2 title="Page Not Found" page="-----" img="assets/img/page-header1.jpg"/>
    </>

  )
}
export default NoPage