import React, {useEffect, useRef} from "react";
import binay from '../../assets/binayjha.jpg'
import bipin from '../../assets/bipinjha.jpg'
// import arvind from '../../assets/arvindshahi.jpg'
import sudhir from '../../assets/Dr_Sudhir_Kumar.jpg'
import sushil from '../../assets/Dr_Sushil_Kumar_Jha.jpg'
import kaushal from '../../assets/Shri_Kaushal_Kumar.jpg'
import saroj from '../../assets/Saroj_Kumar.jpg'
import tarakant from '../../assets/tarakant01.jpg'
import chitra from '../../assets/Chitra.jpeg'


const team = [
    {
        name: "Mr. Binay Jha",
        position: "Co-Founder and CEO of Aapkilathi",
        image: binay,
        about: ""
    },
    {
        name: "Mr. Bipin Jha",
        position: "Evangelist and Chairman of WorkWork Limited, United Kingdom",
        image: bipin,
        about: ""
    },
    // {
    //     name: "Mr. Arvind Shahi",
    //     position: "Chief Risk Officer of a large bank",
    //     image: arvind,
    //     about: ""
    // },
    {
        name: "Mr. Tara Kant Jha",
        position: "Founder, Visionary and Social Crusader",
        image: tarakant,
        about: ""
    },

    {
        name: "Dr. Sudhir Kumar",
        position: "Dentist",
        image: sudhir,
        about: ""
    },
    {
        name: "Dr. Sushil Kumar Jha",
        position: "General Physician",
        image: sushil,
        about: ""
    },
    {
        name: "Shri. Kaushal Kumar",
        position: "Chief Pharmacist",
        image: kaushal,
        about: ""
    },
    {
        name: "Shri. Saroj Kumar",
        position: "Medicine Store Executive",
        image: saroj,
        about: ""
    },
    {
        name: "Shri. Chitra",
        position: "Housekeeping Staff",
        image: chitra,
        about: ""
    }
]

function LpssTeam() {

    useEffect(() => {

    }, []);
    return (
        <div className="tw-w-full tw-relative tw-flex tw-flex-col tw-px-4 md:tw-px-20 lg:tw-px-40 xl:tw-px-44 tw-py-10 lg:tw-py-24 tw-bg-dark tw-text-white tw-bg-center tw-bg-cover tw-bg-no-repeat">
            <div className="tw-w-full tw-flex tw-flex-col tw-justify-end tw-py-6 tw-text-center">
                <h5 className="tw-text-accent tw-text-xl tw-font-bold tw-uppercase tw-mb-4">Our team</h5>
                <p className="tw-text-3xl md:tw-text-5xl tw-font-bold">The Pillars of Lal Pari Swasthya Seva Sadan</p>
                <p className="tw-font-light tw-relative tw-z-10 tw-text-lg lg:tw-text-xl tw-mt-6 tw-text-center lg:tw-w-2/3 tw-mx-auto">
                    At LPS Sadan, we are fortunate to be backed by a team of devoted individuals who are not only adept in their respective fields but are also driven by a common goal - to revitalize and fortify the rural healthcare infrastructure in Devhitol. Each member brings a unique set of skills and a shared commitment to community well-being.
                </p>
            </div>
            <div
                className="tw-w-full tw-grid tw-grid-cols-1 md:tw-grid-cols-2 xl:tw-grid-cols-3 md:tw-gap-3 lg:tw-gap-8 xl:tw-gap-12 tw-bg-white tw-p-4 lg:tw-px-10 tw-py-6 lg:tw-py-8">
                {
                    team.map((member, i) => <div className="tw-w-full tw-flex tw-mt-4 md:tw-mt-0" key={i}>
                        <div className="tw-p-2 lg:tw-p-4 tw-border tw-border-dark tw-rounded-lg member_image tw-relative tw-w-1/3">
                            <img className="tw-rounded-lg tw-object-cover tw-h-full tw-w-full" src={member.image} alt=""/>
                        </div>
                        <div className="tw-pl-4 tw-flex tw-flex-col tw-h-full tw-justify-center tw-flex-1">
                            <h5 className="tw-text-xl tw-font-bold tw-text-complement">{member.name}</h5>
                            <p className="tw-mb-2 tw-text-complement tw-text-sm lg:tw-text-base">{member.position}</p>
                            <p className="tw-font-thin tw-text-left tw-text-complement tw-leading-snug tw-text-sm lg:tw-text-base">
                                {member.about}
                            </p>
                        </div>
                    </div>)
                }
            </div>
        </div>
    )
}

export default LpssTeam;