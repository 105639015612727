import React, {useEffect, useRef} from "react";
import ala from '../../assets/ala.jpg'
import ascend from '../../assets/ascend_logo.jpg'
import work_work from '../../assets/work_work_logo.jpg'


function LpssPartners() {

    useEffect(() => {

    }, []);
    return (
        <div className="tw-w-full tw-relative tw-flex tw-flex-col tw-px-4 md:tw-px-20 lg:tw-px-40 xl:tw-px-44 tw-py-10 lg:tw-py-24 tw-bg-dark tw-text-white tw-bg-center tw-bg-cover tw-bg-no-repeat">
            <div className="tw-w-full tw-flex tw-flex-col tw-justify-end tw-py-6 tw-text-center">
                <h5 className="tw-text-accent tw-text-xl tw-font-bold tw-uppercase tw-mb-4">Our Supporters</h5>
                <p className="tw-text-3xl md:tw-text-5xl tw-font-bold">LPS is committed to an equitable health access to villagers and needy in the rural areas of Bihar.</p>
                <p className="tw-font-light tw-relative tw-z-10 tw-text-lg lg:tw-text-xl tw-mt-6 tw-text-center lg:tw-w-2/3 tw-mx-auto">
                    Gratitude is at the heart of our journey, and we extend our deepest appreciation to all our supporters. Your unwavering belief in our mission fuels our efforts to make a meaningful impact. Together, we are not just creating change; we are shaping a brighter future. Thank you for standing by us, every step of the way.
                </p>
            </div>
            <div
                className="tw-w-full lg:tw-w-3/4 xl:tw-w-3/5 tw-mx-auto tw-grid tw-grid-cols-2 md:tw-grid-cols-3 xl:tw-grid-cols-3 md:tw-gap-3 lg:tw-gap-8 xl:tw-gap-20 tw-p-4 lg:tw-px-10 tw-py-6 lg:tw-py-8">
                <a href="https://www.workworkltd.com" target="_blank" className="">
                    <img src={work_work} alt=""/>
                    <p className="tw-text-center">Work Work Limited</p>
                </a>
                <a href="https://www.ascendtele.com" target="_blank" className="">
                    <img src={ascend} alt=""/>
                    <p className="tw-text-center">Ascend Telecom</p>
                </a>
                <a href="https://www.aapkilathi.com" target="_blank" className="">
                    <img src={ala} alt=""/>
                    <p className="tw-text-center">Aapkilathi</p>
                </a>
            </div>
        </div>
    )
}

export default LpssPartners;