import React, {useState, useEffect} from 'react'
import $ from 'jquery'
import api from '../Service/api';
import axios from "axios";

function Contact() {
    useEffect(() => {
        const body = document.querySelector('#root');
        body.scrollIntoView({
            behavior: 'smooth'
        }, 500)
    }, []);
    const [contact, setContact] = useState({
        uid: "yadu",
        purpose: "enquiry",
        city: "",
        state: "",
        message: "",
        name: "",
        phone: "",
    })

    const handleChange = (event) => {
        setContact(prev => ({...prev, [event.target.name]: event.target.value}));
    };

    async function handleSubmit(event) {
        event.preventDefault();
        const url = 'https://theroguepixxel.com/waapi/contact_submission'
        const requestOptions = {
            method: 'POST',
            mode: "no-cors",
            headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
            body: Object.entries(contact).map(([k,v])=>{return k+'='+v}).join('&')
        };
        await fetch(url, requestOptions)
            .then(async response => {
                $('#success').html("<div class='alert alert-success'>");
                $('#success > .alert-success').html("<button type='button' class='close' data-dismiss='alert' aria-hidden='true'>&times;")
                    .append("</button>");
                $('#success > .alert-success')
                    .append("<strong>Your message has been sent. </strong>");
                $('#success > .alert-success')
                    .append('</div>');
            })
            .catch(async error => {
                $('#success').html("<div class='alert alert-danger'>");
                $('#success > .alert-danger').html("<button type='button' class='close' data-dismiss='alert' aria-hidden='true'>&times;")
                    .append("</button>");
                $('#success > .alert-danger').append($("<strong>").text("Sorry " + contact.name + ", it seems that our server is not responding. Please try again later!"));
                $('#success > .alert-danger').append('</div>');
            });


    }

    return (
        <>
            <div className="contact">
                <div className="section-header text-center">
                    <p>Get In Touch</p>
                    <h2>Contact for any query</h2>
                </div>
                <div className="container">
                    <div className="contact-img">
                        <img src="assets/img/contact.jpg" alt="contact"/>
                    </div>
                    <div className="contact-form">
                        <form onSubmit={handleSubmit} name="sentMessage" id="contactForm">
                            <div className="control-group">
                                <input type="text" className="form-control" id="name" placeholder="Your Name"
                                       required={true} name='name' onChange={handleChange} value={contact.name}/>
                                <p className="help-block text-danger"></p>
                            </div>
                            <div className="control-group">
                                <input type="email" className="form-control" id="email" placeholder="Your Email"
                                       required={true} name='email' onChange={handleChange} value={contact.emails}/>
                                <p className="help-block text-danger"></p>
                            </div>
                            <div className="control-group">
                                <input type="text" className="form-control" id="phone" placeholder="Phone"
                                       required={true} name='phone' onChange={handleChange} value={contact.phone}/>
                                <p className="help-block text-danger"></p>
                            </div>
                            <div className="control-group">
                                <textarea className="form-control" id="message" placeholder="Message"
                                          required={true} name='message'
                                          style={{overflowY: "scroll", height: "160px"}} onChange={handleChange}
                                          value={contact.message}></textarea>
                                <p className="help-block text-danger"></p>
                            </div>
                            <div id="success" style={{position: "relative"}}></div>
                            <div>
                                <button className="btn btn-custom" type="submit" id="sendMessageButton">Send Message
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Contact