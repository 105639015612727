import React from 'react'
import ServiceItem from './ServiceItem';

const data=[
    {
        title:"Social Service",
        detail:"We work towards making a better society for children by giving them an equal opportunity to study. Therefore, we are determined to establish a research center, a library, and other vocational skills. Our team also works to spread awareness about elder support, promote sympathy, sanitation, public health development, and so on.",
        icon:"assets/img/icons/social service.svg",
    },
    {
        title:"Economical Service",
        detail:"Through our educational activities and social welfare, we even award scholarships and financial help to children who come from poor backgrounds and want to pursue technical and medical professions. Our trust also helps the needy students of minority communities to undergo primary, secondary and higher education.",
        icon:"assets/img/icons/economical service.svg",
    },
    {
        title:"Charitable Service",
        detail:"Yadu Nandan Shiksha SEVA SANSTHAN The Trust works dedicatedly to open charitable dispensaries, children's clinics, blood donation centers, blood banks, etc. and help the minority people with marriages, funerals, and cremations.",
        icon:"assets/img/icons/charity service.svg",
    },
    {
        title:"Ecosystem Restoration Service",
        detail:"With our forestation seminar and nature preservation activities, we have determined to save our ecosystem and promote a sense of responsibility towards mother Earth. We plant trees to reduce pollution and greenhouse gases from the earth’s surface. Our team also promotes research development in agriculture, horticulture, tissue culture, and biotechnology. This will help in the rural areas with harvesting food and preservation.",
        icon:"assets/img/icons/ecosystem service.svg",
    },
    {
        title:"Sanskrit Education Service",
        detail:"With time, Sanskrit is losing its roots. Therefore, we at YADU NANDAN SHIKSHA SEVA SANSTHAN Trust are working to establish and maintain one traditional Sanskrit school for the study of subjects – VAIYAKARAN , JYOTISH, VEDAS, and KARM KAND. Also, inspiring children and youth to achieve mental and physical soundness through our yoga centres in the rural communities helps spread the core of our Indian culture.",
        icon:"assets/img/icons/education service.svg",
    },
    {
        title:"Donate Now",
        detail:"Donation is a blessing to the children who are ignored and who continue to be uneducated. By supporting them, you can become their angel for the rest of their lives. You can show your kindness and humanity by donating. Donations are not always financial, so you can also give things like toys, food, textbooks, and books. ",
        icon:"assets/img/icons/donate.svg",
    },
]
function Service() {
    return (
        <>
<div className="service">
    <div className="container">
        <div className="section-header text-center">
            <h2>Areas of Impact.</h2>
        </div>
        <div className="row">
            {data.map((item, index) => <ServiceItem title={item.title} detail={item.detail} icon={item.icon} key={index}/> )}
        </div>
    </div>
</div>
        </>
    )
}

export default Service