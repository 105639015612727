import React from 'react'
import { NavLink } from "react-router-dom";
import '../style/style.css'
function Middle2(props) {
    return (
        <>
            {/* <!-- Carousel Start --> */}
            <div className="carousel carousel2">
                <div className="container-fluid">
                    <div className="owl-carousel">
                        <div className="carousel-item">
                            <div className="carousel-img2">
                                <img src={props.img} alt="header" style={{height:"500px",width:"100%"}}/>
                            </div>
                            {/* <!-- Page Header Start --> */}
                            <div className="page-header">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <h2>{props.title}</h2>
                                        </div>
                                        <div className="col-12">
                                            <NavLink to="/">Home</NavLink>
                                            <NavLink to="">{props.page}</NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Page Header End --> */}
                        </div>

                    </div>
                </div>
            </div>
            {/* <!-- Carousel End --> */}
        </>
    )
}

export default Middle2