import React from 'react';
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function Header() {
  return (
    <>
      {/* <!-- Top Bar Start --> */}
      <div className="top-bar d-none d-md-block">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="top-bar-left">
                <div className="text">
                  <i className="fa fa-phone-alt"></i>
                  <p >+91 9820007185</p>
                </div>
                <div className="text">
                  <i className="fa fa-envelope"></i>
                  <p>yadutrustorg@gmail.com</p>
                </div>
                {/* <div className="text ">
                  {/* <div style={{ justifyContent: 'center', alignItems: 'start', alignSelf: 'start', display: 'flex', flexDirection: 'column' }}> */}

                    {/* <p>
                      <i class="fa fa-info-circle" aria-hidden="true"></i> */}
                      {/* PAN No. - AAATY6820A </p> */}

                  {/* </div> */}
                {/* </div> */}
                <div className=" col-md-2">
                  <div>
                  {/* <p>
                      <i class="fa fa-info-circle" aria-hidden="true"></i>
                      {/* Reg.No. - R-7429 */}
                      {/* </p> */}
                    <div className="social">
                      <a href="https://www.facebook.com/profile.php?id=100086580764534" rel="noreferrer" target="_blank"><i className="fab fa-facebook-f"></i></a>
                      <a href="https://www.linkedin.com/company/yadunandan-shiksha-seva-sansthan"><i className="fab fa-linkedin-in"></i></a>
                      <a href="https://wa.me/919470883299"><i className="fab fa-whatsapp"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Top Bar End --> */}

      {/* <!-- Nav Bar Start --> */}
      <div className="navbar navbar-expand-lg bg-dark navbar-dark">
        <div className="container-fluid ">
          <div className="tw-flex tw-items-center" style={{flexDirection: 'row', color: '#fff'}}>
            <div className="tw-border-r">
              <NavLink to="/" className="navbar-brand">yadu</NavLink>
              <p>Giving is Living</p>
            </div>
            <p className="tw-pl-2 tw-text-sm">
              NGO Darpan ID<br/>
              BR/2023/0375238
            </p>
          </div>
          <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
            <div className="navbar-nav ml-auto">
              <NavLink to="/" className="nav-item nav-link">Home</NavLink>
              <HashLink to="/#about_s" className="nav-item nav-link ">About</HashLink>
              <NavLink to="causes" className="nav-item nav-link ">Our Initiatives</NavLink>
               <NavLink to="lalpari_sewa_sadan" className="nav-item nav-link ">LPS Sadan</NavLink>
              {/*<NavLink to="causes" className="nav-item nav-link ">Our Supporters</NavLink> */}
              {/* <div className="nav-item dropdo--wn">
                <NavLink to="support" className="nav-link dropdown-toggle" data-toggle="dropdown">Our Supporters</NavLink>
                <div className="dropdown-menu">
                  <a href='https://www.aapkilathi.com/' className=" nav-link tw-flex" target="_blank" rel="noreferrer">Aapkilathi</a>
                  <a href='https://workworkltd.com/' className=" nav-link tw-flex" target="_blank" rel="noreferrer">Work Work Ltd</a>
                  {/* <a href='lalpari_sewa_sadan' className=" nav-link" target="_blank" rel="noreferrer">LPS Sadan</a> */}
                {/* </div>
              </div>  */}
              {/* <a href="assets/Audited_financial.pdf" className="nav-item nav-link " target="_blank" rel="noreferrer">Audited Financials</a> */}
              <NavLink to="contact" className="nav-item nav-link">Contact</NavLink>
            </div>

          </div>
        </div>
      </div>
      {/* <!-- Nav Bar End --> */}
    </>
  )
}

export default Header