import React, {useEffect, useRef, useState} from "react";
import map from "../../assets/map.png";
import turningPoint from '../../assets/slides/TurningPoint.jpg'
import turningPoint1 from '../../assets/slides/TurningPoint1.jpg'
import visionBirth from '../../assets/slides/VisionBirth.jpg'
import visionBirth1 from '../../assets/slides/VisionBirth1.jpg'
import adversities from '../../assets/slides/Adversities.jpg'
import adversities1 from '../../assets/slides/Adversities1.jpg'
import spirit from '../../assets/slides/Spirit.jpg'
import spirit1 from '../../assets/slides/Spirit1.jpg'
import healthCenter from '../../assets/slides/HealthCenter.jpg'
import healthCenter1 from '../../assets/slides/HealthCenter1.jpg'
import continues from '../../assets/slides/Continues.jpg'
import continues1 from '../../assets/slides/Continues1.jpg'
import {gsap} from "gsap";
import Carousel from "react-elastic-carousel";



function LpssSocial() {



    useEffect(() => {

    }, []);
    return (
        <div
            className="tw-w-full tw-relative tw-flex tw-flex-col tw-px-4 md:tw-px-20 lg:tw-px-40 xl:tw-px-44 tw-pt-10 lg:tw-pt-24 tw-bg-[#E6F1F7]">
            <div className="tw-flex tw-flex-col lg:tw-flex-row tw-items-start">
                <div
                    className="tw-w-full tw-flex tw-flex-col tw-justify-end tw-py-6 tw-relative tw-z-20 xl:tw-w-1/2">
                    <h5 className="tw-text-accent tw-text-xl tw-font-bold tw-uppercase tw-mb-4">LPS Sadan Activities
                        from
                        Facebook</h5>
                    <p className="tw-text-3xl md:tw-text-5xl tw-font-bold">A Beacon of Hope and Health in Devhitol</p>
                </div>
                <div
                    className="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-py-6 lg:tw-px-4 tw-relative tw-z-20 xl:tw-w-1/2 ">
                    <iframe
                        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Flpsdev%2F&tabs=timeline&width=500&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                        width="500" height="500" style={{border:'none', overflow:'hidden'}} scrolling="no" frameBorder="0"
                        allowFullScreen="true"
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                </div>

            </div>
        </div>
    )
}

export default LpssSocial;